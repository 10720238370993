import CryptoJS from 'crypto-js';

import { getRenderedVideosInfo } from '../api/ServerApi';
import getEngine from '../components/EffectsEngine';

const Engine = await getEngine();

const blobUrlToUint8Array = async (blobUrl: string) => {
  // Step 1: Fetch the Blob from the URL
  const response = await fetch(blobUrl);
  const blob = await response.blob();

  // Step 2: Convert the Blob to an ArrayBuffer
  const arrayBuffer = await blob.arrayBuffer();

  // Step 3: Convert the ArrayBuffer to a Uint8Array
  const uint8Array = new Uint8Array(arrayBuffer);

  return uint8Array;
}

const createVideoFd = async (video: any) => {
  const url = video.url;
  const filename = url.split('/').slice(-1); // get the guid for the blob
  return blobUrlToUint8Array(url).then((arr) => {
    return Engine.writeFile(`${filename}.mp4`, arr).then((_: any) => {
      return Engine.open_movie([`${filename}.mp4`]).then((fd: number) => {
        if (fd == -1) {
          console.error('engine returned bad fd')
          return -1;
        }
        return fd;
      });
    })
  });
}

const throttle: any = (callback: (...args: any[]) => any, throttleSeconds: number): (...args: any[]) => any => {
  let lastActivationTime: number | null = null;
  let activeTimeout = false;
  const throttledFunc = (...args: any[]) => {
    const timeNow = Date.now();

    const timeDiff = (lastActivationTime !== null) ?
      ((timeNow - lastActivationTime) / 1000) :
      throttleSeconds + 1;

    if (timeDiff > throttleSeconds) {
      lastActivationTime = timeNow;
      callback(...args);
    } else {
      if (!activeTimeout) {
        // add a call to the function after 300 seconds so we always have
        // a final call after the user stops
        activeTimeout = true;
        setTimeout(() => {
          activeTimeout = false;
          callback(...args);
        }, throttleSeconds);
      }
    }
  }
  return throttledFunc;
}

const calculateSha256 = async (blob: Blob): Promise<string> => {
  // Convert the Blob to an ArrayBuffer
  const arrayBuffer = await blob.arrayBuffer();

  // Convert the ArrayBuffer to a WordArray (required by CryptoJS)
  const wordArray = CryptoJS.lib.WordArray.create(new Uint8Array(arrayBuffer));

  // Hash the WordArray using SHA-256
  const hash = CryptoJS.SHA256(wordArray);

  // Convert the hash to a hex string
  const hashHex = hash.toString(CryptoJS.enc.Hex);

  return hashHex;
};

const uploadVideo = (url: string, video: Blob, fileType: string, onProgress: (percentage: number) => void): Promise<void> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.withCredentials = true;

    // Set up the progress listener
    xhr.upload.onprogress = (event: ProgressEvent) => {
      if (event.lengthComputable) {
        const percentage = Math.round((event.loaded / event.total) * 100);
        onProgress(percentage);
      }
    };

    // Success and error handlers
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve();  // File uploaded successfully
      } else {
        reject(new Error(`Failed to upload file: ${url}, Status: ${xhr.status}`));
      }
    };

    xhr.onerror = () => reject(new Error('Network error occurred during upload'));

    // Set up and initiate the request
    xhr.open('PUT', url, true);
    xhr.setRequestHeader('Content-Type', fileType);

    // Send the video blob
    xhr.send(video);
  });
};

const pollRenderedVideoAndDownload = async (videoName: string, pollInterval: number) => {
  try {
      const info = await getRenderedVideosInfo();
      if (info === null) {
          // if there is no info, something is wrong - the video was not rendered
          return;
      }
      console.log(info);

      const foundVideos = info.filter((vid: any) => vid.fileName === videoName);
      console.log(foundVideos);
      console.log(videoName);
      if (foundVideos.length === 0) {
        setTimeout(() => pollRenderedVideoAndDownload(videoName, pollInterval), pollInterval);
        return;
      }
      const foundVideo = foundVideos[0];
      const res = await fetch(foundVideo.url, { method: 'GET' });
      if (!res.ok) {
        console.error('Error downloading file:', foundVideo.url);
        // poll every second until we get a response or error
        setTimeout(() => pollRenderedVideoAndDownload(videoName, pollInterval), pollInterval);
        return;
      }
      console.log(res);
      const url = window.URL.createObjectURL(await res.blob());

      // Create a link element and trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.download = 'processed_video.mp4'; // Set the default filename
      document.body.appendChild(link);
      link.click();

      // Cleanup
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
  } catch (err) {
      console.error('Error polling rendered video:', err);
  }

  //// poll every second until we get a response or error
  //setTimeout(() => pollRenderedVideoAndDownload(videoName, pollInterval), pollInterval);
}

export {
  pollRenderedVideoAndDownload,
  calculateSha256,
  uploadVideo,
  blobUrlToUint8Array,
  createVideoFd,
  throttle
}

