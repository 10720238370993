import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Card, Typography, TextField, Button, Select, MenuItem, FormControl, InputLabel, Modal, Grid } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import ConfirmDialog from './ConfirmDialog';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import config from '../constants/constants';
import AppBar from './AppBar';

import { deleteUser } from '../api/ServerApi';

interface User {
  _id: string;
  username: string;
  email: string;
  isAdmin: boolean;
  permissions: string[];
}

interface Permissions {
  [userId: string]: string[];
}

type UserManagementParams = {
  shouldOpenAddUserModal: string;
};

const UserManagement: React.FC = () => {
  const { shouldOpenAddUserModal } = useParams<UserManagementParams>();

  const [users, setUsers] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [newUser, setNewUser] = useState<{ username: string; email: string; isAdmin: boolean }>({ username: '', email: '', isAdmin: false });
  const [permissions, setPermissions] = useState<Permissions>({});
  const [filter, setFilter] = useState<string>('');
  const [selectedRow, setSelectedRow] = useState<any>();
  const [openModal, setOpenModal] = useState<boolean>(shouldOpenAddUserModal === 'true');
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  useEffect(() => {
    setFilteredUsers(
      users.filter(user =>
        user.username.toLowerCase().includes(filter.toLowerCase())
      )
    );
  }, [filter, users]);

  const reloadContent = () => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${config.SERVER_ADDRESS}${config.FETCH_USERS_PATH}`, {
          method: 'GET',
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error('Failed to fetch users');
        }

        return await response.json();
      } catch (error) {
        console.error('Failed to fetch users', error);
        return [];
      }
    }
    fetchUsers().then(users => {
      if (users) {
        setUsers(users);
      }
    }).catch((error: any) => {
      console.error("failed to fetch users", error);
    });
  }

  useEffect(() => {
    reloadContent();
  }, []);

  const inviteUser = async () => {
    const addUser = async () => {
        try {

        const response = await fetch(`${config.SERVER_ADDRESS}${config.ADD_USER_PATH}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include',
          body: JSON.stringify(newUser)
        });

        if (!response.ok) {
          throw new Error('Failed to add user');
        }

        return await response.json();
      } catch (error) {
        console.error('Failed to add user', error);
        return null;
      }
    }
    addUser().then(user => {
      console.log(user);
      if (user) {
        setUsers([...users, user]);
        setFilteredUsers([...users, user]);
        setNewUser({ username: '', email: '', isAdmin: false });
        setOpenModal(false);
        reloadContent();
      }
    }).catch((error: any) => {
      console.error("failed to fetch users", error);
      setNewUser({ username: '', email: '', isAdmin: false });
      setOpenModal(false);
    });
  };

  const deleteUserClicked = async (event: any, row: any) => {
    setSelectedRow(row);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogConfirm = () => {
    setConfirmDialogOpen(false);
    try {
      deleteUser(selectedRow._id).then(() => {
        console.log("successfuly deleted user");
        reloadContent();
      });
      // Mock API update
    } catch (error) {
      console.error('Error deleting user', error);
    }
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const columns: GridColDef[] = [
    { field: 'username', headerName: 'Name', width: 150 },
    { field: 'email', headerName: 'Email', flex: 1,
      renderCell: (params) => (
        <Box sx={{display: 'flex', flexDirection: 'row'}}>
          <div style={{flexGrow: 1}}> {params.row.email} </div>
          <IconButton onClick={(event) => deleteUserClicked(event, params.row)}>
            <DeleteOutlineIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <div style={{ flexDirection: 'column', display: 'flex', flexGrow: 1, overflowX: 'hidden' }}>
      <div style={{position: 'fixed', top: '48px', width: '100%', zIndex: 999}}>
        <AppBar title={'Users'} color="inherit" />
      </div>
      <div className="pageContainer" >
        <Card className="cardContainer">
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <TextField
              label="Search by name"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              sx={{ marginRight: '10px' }}
            />
            <Button variant="contained" color="primary" onClick={() => setOpenModal(true)}>Add User</Button>
          </Box>
          <div style={{ height: '100%', width: '100%' }}>
            <DataGrid
              rows={filteredUsers}
              columns={columns}
              getRowId={(row) => row._id}
              disableRowSelectionOnClick
            />
          </div>
        </Card>
      </div>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box sx={{ display: 'flex', flexDirection: 'column', p: 2, bgcolor: 'background.paper', boxShadow: 24, width: 400, margin: 'auto', mt: 10 }}>
          <Typography variant="h6">Add New User</Typography>
          <TextField
            label="Username"
            value={newUser.username}
            onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
            margin="normal"
          />
          <TextField
            label="Email"
            value={newUser.email}
            onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
            margin="normal"
          />
          <Button variant="contained" color="primary" onClick={inviteUser} sx={{ mt: 2 }}>Invite User</Button>
        </Box>
      </Modal>
      <ConfirmDialog
        open={confirmDialogOpen}
        title="Confirm Deletion"
        content="Are you sure you want to delete this item?"
        onClose={handleConfirmDialogClose}
        onConfirm={handleConfirmDialogConfirm}
      />
    </div>
  );
};

export default UserManagement;

