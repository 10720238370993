import React, { useEffect, useState } from 'react';
import { Slider, SliderThumb, Box } from '@mui/material';
import { styled } from '@mui/system';

// Styled Slider without track fill, only line and triangle
const CustomSlider = styled(Slider)({
  padding: 0,
  height: 0,
  '& .MuiSlider-thumb': {
    width: 0.5,
    height: 0.3,
    '&:hover, &.Mui-focusVisible, &.Mui-active': {
      boxShadow: 'none',
    },
  },
  '& .MuiSlider-track': {
    display: 'none', // Hide the filled track
  },
  '& .MuiSlider-rail': {
    display: 'none',
    backgroundColor: 'grey',
    height: 0
  },
});

function ThumbComponent(props: any) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <Box
        sx={{
          width: 2,
          height: 0,
          borderLeft: '10px solid transparent',
          borderRight: '10px solid transparent',
          borderTop: '16px solid black',
          position: 'absolute',
          top: '-14px',
        }}
      />
      <Box
        sx={{
          width: 2,
          left: '-1px',
          height: '1000px',
          backgroundColor: 'black',
          position: 'absolute',
          transform: 'translateY(50%)',
        }}
      />
    </SliderThumb>
  );
}

interface FrameSliderProps {
  isThumbVisible: boolean;
  value: number;
  onChange: (event: Event, newValue: number | number[]) => void;
  min: number;
  totalDuration: number;
  maxSliderOffset: number;
  step: number;
  markerGap: number;
}

const FrameSlider: React.FC<FrameSliderProps> = ({ isThumbVisible, value, min, totalDuration, maxSliderOffset, step, markerGap, onChange }) => {
  const [sliderValue, setSliderValue] = useState<number>(value);

  useEffect(() => {
    setSliderValue(Math.min(value, maxSliderOffset));
  }, [value, markerGap, maxSliderOffset]);

  const generateMarkers = () => {
    const markers = [];
    markers.push(
      <Box
        key={0}
        sx={{
          position: 'absolute',
          left: '0px',
          top: '10px',
          transform: 'translateX(-50%)',
          textAlign: 'center',
        }}
      ></Box>
    );
    const numMarkers = Math.floor(window.innerWidth / markerGap);
    for (let i = 1; i < numMarkers; i++) {
      const time = (i / numMarkers) * totalDuration;
      markers.push(
        <Box
          key={i}
          sx={{
            position: 'absolute',
            left: `${(i / numMarkers) * 100}%`,
            top: '10px',
            transform: 'translateX(-50%)',
            textAlign: 'center',
          }}
        >
          <Box
            sx={{
              fontSize: '0.8em',
              color: 'black',
              marginBottom: '2px',
            }}
          >
            {formatTime(time)}
          </Box>
          <Box
            sx={{
              width: '1px',
              height: '10px',
              backgroundColor: 'black',
              margin: '0 auto',
            }}
          />
        </Box>
      );
    }
    return markers;
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleChange = (event: any, newValue: any) => {
    newValue = Math.min(newValue, maxSliderOffset);
    setSliderValue(newValue);
    onChange(event, newValue);
  };

  const handleClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    const sliderRect = event.currentTarget.getBoundingClientRect();
    const clickPosition = event.clientX - sliderRect.left; // Position relative to the slider
    const newValue = Math.min(min + (clickPosition / sliderRect.width) * (totalDuration - min), maxSliderOffset);
    setSliderValue(newValue);
    onChange(event, newValue);
  };

  return (
    <Box sx={{ position: 'relative', width: '100%', height: '100%', cursor: 'pointer' }} onClick={handleClick}>
      <Box sx={{ position: 'relative', height: '20px' }}>{generateMarkers()}</Box>
      <CustomSlider
        value={sliderValue}
        slots={{ thumb: ThumbComponent }}
        min={min}
        max={totalDuration}
        step={step}
        onChange={handleChange}
        style={{ zIndex: 3, display: isThumbVisible ? 'block' : 'none' }}
      />
    </Box>
  );
};

export { FrameSlider };
