import React, { useState } from 'react';
import {
  Container,
  CssBaseline,
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  Link,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import ZetanoxLogo from '../../assets/zevideo-black-no-background.webp';
import { useAuth } from '../AuthContext';
import { registerUser } from '../../api/ServerApi';

const theme = createTheme({
  palette: {
    primary: {
      main: '#121e2a', // Replace with your desired primary color
    },
    secondary: {
      main: '#dc004e', // Replace with your desired secondary color
    },
  },
});

const Root = styled('div')({
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: 'url(https://source.unsplash.com/random)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
});

const Paper = styled(Box)({
  marginTop: theme.spacing(0),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  padding: theme.spacing(3),
  borderRadius: '8px',
});

const Form = styled(Box)({
  width: '100%', // Fix IE 11 issue.
  marginTop: theme.spacing(3),
});

const SubmitButton = styled(Button)({
  margin: theme.spacing(3, 0, 2),
});

const Logo = styled('img')({
  width: '100px',
  marginBottom: theme.spacing(2),
});

export default function RegistrationPage() {
  const { isAuthenticated, loading, login } = useAuth();

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    username: '',
    password: '',
    confirmPassword: '',
  });

  const navigate = useNavigate();

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    registerUser(formData.fullName, formData.email, formData.username, formData.password).then((data) => {
      console.log('Registration successful:', data);

      navigate(`/mailsent/${formData.email}`);
    }).catch ((error) => {
      console.error('Registration error:', error);
      // Handle login error, e.g., show an error message
    });

    // Add your form submission logic here
    console.log(formData);
  };

  return (
    <ThemeProvider theme={theme}>
      <Root sx={{overflowY: 'auto'}}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Paper>
            <Logo src={ZetanoxLogo} alt="Zetanox Logo" style={{ width: '360px', height: '165px' }}/>
            <Typography component="h1" variant="h5">
              Start Your Free Trial
            </Typography>
            <Form component="form" onSubmit={handleSubmit} >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name="fullName"
                    required
                    fullWidth
                    id="fullName"
                    label="Full Name"
                    autoFocus
                    value={formData.fullName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <SubmitButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Register
              </SubmitButton>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link href="/login" variant="body2">
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
            </Form>
          </Paper>
        </Container>
      </Root>
    </ThemeProvider>
  );
}

