
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Tooltip, Divider, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import Typography from '@mui/material/Typography';
import PhotoFilterIcon from '@mui/icons-material/PhotoFilter';
import TextFieldsIcon from '@mui/icons-material/TextFields';

const drawerWidth = 70;

type NavbarIconProps = {
  icon: React.ReactNode,
  name: string,
  route: string,
};

const NavbarIcon: React.FC<NavbarIconProps> = ({icon, name, route}) => {
  return (
    <>
      <Tooltip title={name} placement="right">
        <ListItem className="navbar-item" button key={name} component={RouterLink} to={route} sx={{ 
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '68px' ,
          marginBottom: '20px',
        }}>
          <ListItemIcon sx={{ 
            justifyContent: 'center',
            minWidth: '0',
          }}>
            {icon}
          </ListItemIcon>
          <ListItemText
            primary={
                <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>
                  {name}
                </Typography>
              }
            sx={{ fontSize: '2rem', marginTop: '8px' }}/>
        </ListItem>
      </Tooltip>
    </>
  );
}

interface NavbarProps {
  isMobileNavbar: boolean;
  onFiltersClicked: () => void;
  onTextClicked: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ isMobileNavbar, onFiltersClicked, onTextClicked}) => {
 return (
    <Box sx={{ position: 'relative', height: '100%', width: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
      <Drawer
        sx={{
          overflow: 'hidden',
          width: isMobileNavbar ? '100%' : drawerWidth,
          height: isMobileNavbar ? drawerWidth : '100%',
          flexDirection: 'column',
          flexGrow: 1,
          '& .MuiDrawer-paper': {
            position: 'relative',
            overflow: 'hidden',
            width: isMobileNavbar ? '100%' : drawerWidth,
            height: isMobileNavbar ? drawerWidth : '100%',
            boxSizing: 'border-box',
          },
          alignItems: 'center',
          justifyContent: 'center'
        }}
        variant="permanent"
      >

        <Divider />

        <List sx={{ flexGrow: 1 }}>
          <Tooltip title="" placement="right">
            <ListItem
              button
              onClick={onFiltersClicked}
              sx={{
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: isMobileNavbar ? '68px' : '100%',
                height: isMobileNavbar ? '100%' : '68px',
                marginBottom: '20px',
              }}
            >
              <ListItemIcon
                sx={{
                  justifyContent: 'center',
                  minWidth: '0',
                }}
              >
                <PhotoFilterIcon className="icon" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>
                    Filters
                  </Typography>
                }
                sx={{ fontSize: '2rem', marginTop: '8px' }}
              />
            </ListItem>
          </Tooltip>
          {/*
          <Tooltip title="Text" placement="right">
            <ListItem
              button
              onClick={onTextClicked}
              sx={{
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '68px',
                marginBottom: '20px',
              }}
            >
              <ListItemIcon
                sx={{
                  justifyContent: 'center',
                  minWidth: '0',
                }}
              >
                <TextFieldsIcon className="icon" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>
                    Text
                  </Typography>
                }
                sx={{ fontSize: '2rem', marginTop: '8px' }}
              />
            </ListItem>
          </Tooltip>
        */}
        </List>
      </Drawer>
    </Box>
  );
};

export default Navbar;

