import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Avatar,
  IconButton,
  InputAdornment
} from '@mui/material';
import { styled } from '@mui/system';
import { PhotoCamera, Edit, Check } from '@mui/icons-material';

interface UserProfileProps {
  email: string;
  username: string;
  fullName: string;
  profilePicture: string;
  onUpdate: (
    email: string,
    username: string,
    fullName: string,
    profilePicture: string,
    password: string
  ) => void;
}

const Input = styled('input')({
  display: 'none',
});

const UserProfileModal: React.FC<UserProfileProps> = ({
  email,
  username,
  fullName,
  profilePicture,
  onUpdate
}) => {
  const [userEmail, setUserEmail] = useState(email);
  const [userName, setUserName] = useState(username);
  const [userFullName, setUserFullName] = useState(fullName);
  const [userProfilePicture, setUserProfilePicture] = useState(profilePicture);
  const [password, setPassword] = useState('');
  const [editField, setEditField] = useState<string | null>(null);

  const handlePictureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target && typeof event.target.result === 'string') {
          setUserProfilePicture(event.target.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleFieldEdit = (field: string) => {
    if (editField === field) {
      setEditField(null);
    } else {
      setEditField(field);
    }
  };

  const handleSave = () => {
    onUpdate(
      userEmail,
      userName,
      userFullName,
      userProfilePicture,
      password
    );
    setPassword('');
    setEditField(null);
  };

  return (
    <Box
      sx={{
        border: '1px solid #ddd',
        padding: 2,
        borderRadius: 2,
        maxWidth: 400,
        margin: '0 auto',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Avatar src={userProfilePicture} sx={{ width: 100, height: 100, marginBottom: 2 }} />
        <label htmlFor="profile-picture-upload">
          <Input
            accept="image/*"
            id="profile-picture-upload"
            type="file"
            onChange={handlePictureChange}
          />
          <Button variant="contained" component="span">
            Change Picture
          </Button>
        </label>
      </Box>
      <Box sx={{ mt: 2 }}>
        <TextField
          fullWidth
          label="Email"
          variant="outlined"
          value={userEmail}
          onChange={(e) => setUserEmail(e.target.value)}
          margin="normal"
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          fullWidth
          label="Full Name"
          variant="outlined"
          value={userFullName}
          onChange={(e) => setUserFullName(e.target.value)}
          margin="normal"
          InputProps={{
            readOnly: editField !== 'fullName',
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => handleFieldEdit('fullName')}>
                  {editField === 'fullName' ? <Check /> : <Edit />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          fullWidth
          label="Username"
          variant="outlined"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
          margin="normal"
          InputProps={{
            readOnly: editField !== 'username',
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => handleFieldEdit('username')}>
                  {editField === 'username' ? <Check /> : <Edit />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          fullWidth
          label="Password"
          variant="outlined"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          margin="normal"
          InputProps={{
            readOnly: editField !== 'password',
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => handleFieldEdit('password')}>
                  {editField === 'password' ? <Check /> : <Edit />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          helperText="Leave blank to keep the current password"
        />
        <Button variant="contained" color="primary" fullWidth sx={{ mt: 2 }} onClick={handleSave}>
          Save Changes
        </Button>
      </Box>
    </Box>
  );
};

export default UserProfileModal;

