import React, { useRef } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { ZoomIn, ZoomOut, VolumeOff, Delete, ContentCut, SkipPrevious, PlayArrow, Pause, SkipNext, Add } from '@mui/icons-material';

import ZoomBar from './ZoomBar';

type VideoEditorControlsProps = {
    currentTime: number;
    maxZoom: number;
    isMobileLayout: boolean,
    isRunning: boolean;
    handlePlayPause: () => void;
    handleSkipFrame: (direction: 'forward' | 'backward') => void;
    handleCut: () => void;
    handleDelete: () => void;
    handleRemoveSound: () => void;
    handleZoomChange: (zoomValue: number) => void;
    handleAdd: (file: File) => void;
};

const formatTime = (timeInSeconds: number): string => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    const milliseconds = Math.floor((timeInSeconds % 1) * 1000);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}:${milliseconds < 100 ? '0' : ''}${milliseconds < 10 ? '0' : ''}${milliseconds}`;
};

const VideoEditorControls: React.FC<VideoEditorControlsProps> = ({
    currentTime,
    maxZoom,
    isMobileLayout,
    isRunning,
    handlePlayPause,
    handleSkipFrame,
    handleCut,
    handleDelete,
    handleRemoveSound,
    handleAdd,
    handleZoomChange
}) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file && file.type.startsWith('video/')) {
            handleAdd(file);
        }
    };

    const handleOpenFileDialog = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" px={1}>
            {/* Left-aligned icons */}
            <Box display="flex" alignItems="center">
                <IconButton sx={{ width: '40px' }} onClick={handleOpenFileDialog}>
                    <Add />
                </IconButton>
                <IconButton sx={{ width: '40px' }} onClick={handleCut}>
                    <ContentCut />
                </IconButton>
                {/*
                <IconButton sx={{ width: '40px' }} onClick={handleRemoveSound}>
                    <VolumeOff />
                </IconButton>
                */}
                <IconButton sx={{ width: '40px' }} onClick={handleDelete}>
                    <Delete />
                </IconButton>
            </Box>

            {/* Center-aligned icons */}
            <Box display="flex" alignItems="center">
                <IconButton onClick={() => handleSkipFrame('backward')}>
                    <SkipPrevious />
                </IconButton>
                <IconButton onClick={handlePlayPause}>
                    {!isRunning ? <PlayArrow /> : <Pause />}
                </IconButton>
                <IconButton onClick={() => handleSkipFrame('forward')}>
                    <SkipNext />
                </IconButton>
                {!isMobileLayout && (
                <Typography variant="h6" sx={{ letterSpacing: '0.05em', fontWeight: 'bold' }}>
                    {formatTime(currentTime)}
                </Typography>
                )}
            </Box>

            <Box display="flex" alignItems="center">
                <input
                    ref={fileInputRef}
                    type="file"
                    accept="video/*"
                    style={{ display: 'none' }}
                    onChange={handleFileSelect}
                />
                <ZoomBar isMobileLayout={isMobileLayout} minZoom={100} maxZoom={maxZoom} initialZoom={0} onZoomChange={handleZoomChange} />
                {/*
                <IconButton sx={{ width: '40px' }} onClick={handleZoomOut}>
                    <ZoomOut />
                </IconButton>
                <IconButton sx={{ width: '40px' }} onClick={handleZoomIn}>
                    <ZoomIn />
                </IconButton>
                */}
            </Box>
        </Box>
    );
};

export default VideoEditorControls;
