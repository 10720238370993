
import React, { useState } from 'react';
import {
  Container,
  CssBaseline,
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  Link,
} from '@mui/material';
import { useNavigate, Navigate } from 'react-router-dom';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import ZevideoLogo from '../assets/zevideo-black-no-background.webp';
import { useAuth } from './AuthContext';
import { loginUser, resetUserPassword } from '../api/ServerApi';

import mixpanel from 'mixpanel-browser';

const theme = createTheme({
  palette: {
    primary: {
      main: '#121e2a', // Replace with your desired primary color
    },
    secondary: {
      main: '#dc004e', // Replace with your desired secondary color
    },
  },
});

const Root = styled('div')({
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: 'url(https://source.unsplash.com/random)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
});

const Paper = styled(Box)({
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: 'rgba(255, 255, 255, 1.0)',
  padding: theme.spacing(3),
  borderRadius: '8px',
});

const Form = styled(Box)({
  width: '100%', // Fix IE 11 issue.
  marginTop: theme.spacing(3),
});

const SubmitButton = styled(Button)({
  margin: theme.spacing(3, 0, 2),
});

const Logo = styled('img')({
  width: '100px',
  marginBottom: theme.spacing(2),
});

export default function LoginPage() {
  const { isAuthenticated, loading, login } = useAuth();

  const [isForgotPasswordState, setIsForgotPasswordState] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const navigate = useNavigate();

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleForgotPasswordSubmit = () => {
    resetUserPassword(formData.email).then((data) => {
       console.log('Reset successful:', data);
     }).catch ((error) => {
       console.error('Reset error:', error);
     });
    setIsSubmitDisabled(true);
  };

  const handleLoginSubmit = () => {
    loginUser(formData.email, formData.password).then((data) => {
       console.log('Login successful:', data);

       mixpanel.track('Sign In', {
         'Signin Type': 'Direct'
       })
       mixpanel.identify(data.email)
       mixpanel.people.set({ 
         '$name': data.username,
         '$email': data.email,
         'tenant' : data.tenant
       });
       // Handle login success, e.g., redirect or save the auth token
       login(data.username, data.tenant, data.email, data.fullName, data.isAdmin);
       navigate('/home');
     }).catch ((error) => {
       console.error('Login error:', error);
       // Handle login error, e.g., show an error message
     });

     // Add your form submission logic here
     console.log(formData);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (isForgotPasswordState) {
      handleForgotPasswordSubmit();
    } else {
      handleLoginSubmit();
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="loginPage overlay">
      <Root sx={{overflowY: 'auto'}}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Paper>
            <Logo src={ZevideoLogo} alt="Zevideo Logo" style={{ width: '380px', height: '175px', marginBottom: '0px', marginTop: '0px' }}/>
            <Form component="form" onSubmit={handleSubmit} >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </Grid>
                { !isForgotPasswordState ?
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                </Grid>
                : <></>}
              </Grid>
              <SubmitButton
                disabled={isSubmitDisabled}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                {!isForgotPasswordState ? "Login" : "Reset Password"}
              </SubmitButton>
              { isSubmitDisabled &&
                <Typography variant="body2" color="secondary">
                  An email with instructions has been sent to your inbox
                </Typography>
              }
              <Grid container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                <Grid item sx={{ flexGrow: 1 }}>
                  <Link onClick={() => {
                    setIsSubmitDisabled(false);
                    setIsForgotPasswordState(!isForgotPasswordState);
                  }} href="#" variant="body2">
                    {isForgotPasswordState ? "Log In" : "Forgot your password?"}
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="https://zevideo.com/index.php/privacy" variant="body2">
                    Privacy Policy
                  </Link>
                </Grid>
              </Grid>
            </Form>
          </Paper>
        </Container>
      </Root>
      </div>
    </ThemeProvider>
  );
}

