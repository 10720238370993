import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import config from '../constants/constants';

interface AuthContextType {
  isAuthenticated: boolean;
  isAdmin: boolean;
  username: string;
  tenant: string;
  email: string;
  fullName: string;
  login: (username: string, tenant: string, email: string, fullName: string, isAdmin: boolean) => void;
  updateAuthUserData: (username: string, tenant: string, email: string, fullName: string) => void;
  logout: () => void;
  loading: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [fullName, setFullName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [tenant, setTenant] = useState<string>('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await fetch(`${config.SERVER_ADDRESS}${config.CHECK_AUTH_PATH}`, {
          credentials: 'include',
        });
        const data = await response.json();
        setIsAuthenticated(data.isAuthenticated);
        setIsAdmin(data.isAdmin);
        setUsername(data.username ? data.username : '');
        setTenant(data.tenant ? data.tenant : '');
        setEmail(data.email ? data.email : '');
        setFullName(data.fullName ? data.fullName : '');
      } catch (error) {
        console.error('Failed to check authentication', error);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  const updateAuthUserData = (username: string, tenant: string, email: string, fullName: string) => {
    setUsername(username);
    setTenant(tenant);
    setEmail(email);
    setFullName(fullName);
  }

  const login = (username: string, tenant: string, email: string, fullName: string, isAdmin: boolean) => {
    setIsAuthenticated(true);
    setIsAdmin(isAdmin);
    setUsername(username);
    setTenant(tenant);
    setEmail(email);
    setFullName(fullName);
  }

  const logout = () => {
    setIsAuthenticated(false);
    setUsername('');
    setTenant('');
    setEmail('');
    setFullName('');
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, username, tenant, email, fullName, isAdmin, updateAuthUserData, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

