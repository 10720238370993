import React from 'react';
import { Grid, Card, CardActionArea, CardContent, Typography, CardMedia, Box } from '@mui/material';
import { effectsData } from './Effect';

type FiltersMenuProps = {
    onSelect: (effectName: string) => void;
};

const FiltersMenu: React.FC<FiltersMenuProps> = ({ onSelect }) => {
    return (
        <Box sx={{
            height: '100%',
            overflowY: 'auto',
            overflowX: 'hidden',
            padding: 2 }}>
            <Grid container spacing={3}>
                {effectsData.map((effect) => (
                    <Grid item xs={6} sm={6} md={6} key={effect.name}>
                        <Card sx={{ height: '100%' }}>
                            <CardActionArea onClick={() => onSelect(effect.name)}>
                                <CardMedia
                                    component="img"
                                    height="160"
                                    image={effect.image}
                                    alt={effect.name}
                                />
                                <CardContent>
                                    <Typography variant="h6" component="div">
                                        {effect.name}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {effect.description}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default FiltersMenu;
