import React, { useState, useEffect } from 'react';
import { Modal, Box, AppBar, Toolbar, IconButton, Typography, Avatar, Menu, MenuItem } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';

import { updateProfile } from '../api/ServerApi';
import UserProfileModal from './UserProfileModal';
import config from '../constants/constants';
import ZevideoLogo from '../assets/zevideo-long-white-no-frame-transparent.webp';

const topBarHeight = 48;

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const { logout, updateAuthUserData, email, fullName, username, tenant, isAuthenticated } = useAuth();

  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [curUsername, setCurUsername] = useState(username);
  const [curTenant, setCurTenant] = useState(tenant);

  const navigate = useNavigate();

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    setCurUsername(username);
    setCurTenant(tenant)
  }, [username, tenant, isAuthenticated]);

  const onProfileUpdate = (
    email: string,
    username: string,
    fullName: string,
    profilePicture: string,
      password: string) => {
    updateProfile(email, username, fullName, password).then((data) => {
      console.log(data);
      updateAuthUserData(data.username, data.tenant, data.email, data.fullName);
    });
    setProfileModalOpen(false);
  }

  const onTitleClicked = (event: any) => {
    navigate('/home');
  }

  const handleProfile = () => {
      setProfileModalOpen(true);
      setAnchorEl(null);
  }

  const handleLogout = () => {
    const sendLogout = async () => {
        try {

        const response = await fetch(`${config.SERVER_ADDRESS}${config.LOGOUT_PATH}`, {
          method: 'GET',
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error('Failed to logout');
        }

        return await response.json();
      } catch (error) {
        console.error('Failed to logout', error);
        return null;
      }
    }
    sendLogout().then(dashboards => {
      logout();
      navigate(`/login`);
    });

    setAnchorEl(null);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar position="fixed" sx={{
          zIndex: 1,
          boxShadow: 'none',
          borderBottom: '1px solid lightgray', // Add a light border
          maxHeight: `${topBarHeight}px`,
          padding: 0,
          paddingLeft: 0
        }}
      >
        <Toolbar variant="dense" sx={{ paddingLeft: {xs: 0, sm: 0} }}>
          <Box onClick={onTitleClicked} style={{ cursor: 'pointer' }}>
              <img onClick={onTitleClicked} src={ZevideoLogo} alt="Zevideo Logo" style={{ paddingLeft: '10px', width: '150px', height: '38px' }} />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', flexGrow: 1 }}>
            {/*
            <IconButton color="inherit">
              <NotificationsIcon />
            </IconButton>
            <IconButton color="inherit">
              <SettingsIcon />
            </IconButton>
            */}
            <div onClick={handleMenu} style={{ marginLeft: '10px', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              <Avatar alt={curUsername} src="/path-to-profile.jpg" sx={{ width: '28px', height: '28px' }} />
              <Typography variant="body1" style={{ marginLeft: '10px', color: 'white' }}>{curUsername}</Typography>
            </div>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              keepMounted
            >
              <MenuItem onClick={handleProfile}>Profile</MenuItem>
              {/*<MenuItem onClick={handleClose}>My account</MenuItem>*/}
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
        <Modal
          open={profileModalOpen}
          onClose={() => setProfileModalOpen(false)}
          aria-labelledby="user-profile-modal"
          aria-describedby="user-profile-modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}
          >
            <UserProfileModal
              email={email}
              username={username}
              fullName={fullName}
              profilePicture={"none"}
              onUpdate={onProfileUpdate}
            />
          </Box>
        </Modal>
    </>
  );
};

export {
  topBarHeight
};

export default Navbar;

