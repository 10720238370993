import Video from '../components/Video';

type Layer = {
  start: number;
  end: number;
  rowNumber: number;
  video: Video;
}

type Segment = {
  layers: Layer[];
  start: number;
  end: number;
}

const serializeSegments = (segments: Segment[], canvasWidth: number, canvasHeight: number, videoWidth: number, videoHeight: number): string => {
  const serialized = JSON.stringify({videoWidth: videoWidth, videoHeight: videoHeight, width: canvasWidth, height: canvasHeight, segments: segments.map((segment: Segment) => {
    return {...segment, layers: segment.layers.map((layer: Layer) => {
      return {...layer, video: layer.video.getSerializable(), effectString: layer.video.getEffectString(true)};
    })
  }})})
  return serialized;
}

export {
  serializeSegments,
  type Segment
}