import config from '../constants/constants'
;
const fetchIntegrations = async () => {
  try {
    const response = await fetch(`${config.SERVER_ADDRESS}${config.LIST_SOURCES_PATH}`, {
      method: 'GET',
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch integrations');
    }

    return await response.json();
  } catch (error) {
    console.error('Failed to fetch integrations', error);
    return null;
  }
}

const fetchDestinations = async () => {
  try {
    const response = await fetch(`${config.SERVER_ADDRESS}${config.LIST_DESTINATIONS_PATH}`, {
      method: 'GET',
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch integrations');
    }

    return await response.json();
  } catch (error) {
    console.error('Failed to fetch integrations', error);
    return null;
  }
}

const fetchRemoteExports = async () => {
  try {
    const response = await fetch(`${config.SERVER_ADDRESS}${config.FETCH_REMOTE_EXPORTS_PATH}`, {
      method: 'GET',
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch exports');
    }

    return await response.json();
  } catch (error) {
    console.error('Failed to fetch exports', error);
    return null;
  }
}

const fetchLocalExports = async () => {
  try {
    const response = await fetch(`${config.SERVER_ADDRESS}${config.FETCH_LOCAL_EXPORTS_PATH}`, {
      method: 'GET',
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch exports');
    }

    return await response.json();
  } catch (error) {
    console.error('Failed to fetch exports', error);
    return null;
  }
}

const fetchRawData = async (metrics: string[], dimension: string, platforms: string[], startDate: Date, endDate: Date) => {
  try {
    const start = startDate.toISOString();
    const end = endDate.toISOString();
    const platformsParam = platforms.join(',');

    const params = {
      metrics,
      dimension,
      platforms,
      startDate,
      endDate
    }

    const response = await fetch(`${config.SERVER_ADDRESS}${config.FETCH_RAW_DATA_PATH}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify(params)
    });

    if (!response.ok) {
      throw new Error('Failed to fetch raw data');
    }

    return await response.json();
  } catch (error) {
    console.error('Failed to fetch raw data', error);
    return null;
  }
}

const deleteExport = async (id: string) => {
  try {
    if (!id) {
      return null;
    }
    const response = await fetch(`${config.SERVER_ADDRESS}${config.REMOVE_EXPORT_PATH}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({ id })
    });

    if (!response.ok) {
      throw new Error('Failed to delete export');
    }

    return await response.json();
  } catch (error) {
    console.error('Failed to delete export', error);
    return null;
  }
}

const deleteIntegration = async (id: string): Promise<void> => {
  try {
    const response = await fetch(config.SERVER_ADDRESS + config.REMOVE_INTEGRATION_PATH, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({
        'id': id
      }).toString(),
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Removing integration failed');
    }

    const data = await response;
    console.log('Removing integration successful:', data);
  } catch(error) {
    console.error("Failed to contact server", error);
  }
};

const deleteUser = async (id: string) => {
  if (!id) {
    return null;
  }
  const response = await fetch(`${config.SERVER_ADDRESS}${config.REMOVE_USER_PATH}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ id })
  });

  if (!response.ok) {
    throw new Error('Failed to delete export');
  }

  return await response.json();
}

const updateProfile = async (email: string, username: string, fullName: string, password: string) => {
  if (!email || !username) {
    return null;
  }
  // TODO: need to implement this
  const profilePicture = '';
  const response = await fetch(`${config.SERVER_ADDRESS}${config.UPDATE_PROFILE_PATH}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ email, username, fullName, profilePicture, password })
  });

  if (!response.ok) {
    throw new Error('Failed to update user profile');
  }

  return await response.json();
}

const registerViaToken = async (token: string, username: string, password: string) => {
  if (!token || !username || !password) {
    throw new Error('parameters to registerViaToken cannot be null');
  }

  const response = await fetch(`${config.SERVER_ADDRESS}${config.REGISTER_USER_VIA_TOKEN_PATH}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ token, username, password })
  });

  if (!response.ok) {
    throw new Error('Failed to reigster user via token');
  }

  return await response.json();
}

const resendRegistration = async (email: string) => {
  if (!email) {
    throw new Error('parameters to resendRegistration cannot be null');
  }

  const response = await fetch(`${config.SERVER_ADDRESS}${config.RESEND_REGISTRATION}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ email })
  });

  if (!response.ok) {
    throw new Error(`Failed to resend registration for ${email}`);
  }

  return await response.json();
}

const resetPasswordViaToken = async (token: string, password: string) => {
  if (!token || !password) {
    throw new Error('parameters to resetPasswordViaToken cannot be null');
  }

  const response = await fetch(`${config.SERVER_ADDRESS}${config.RESET_PASSWORD_VIA_TOKEN_PATH}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ token, password })
  });

  if (!response.ok) {
    throw new Error('Failed to reset user password via token');
  }

  return await response.json();
}

const activateViaToken = async (token: string) => {
  if (!token) {
    throw new Error('parameters to registerViaToken cannot be null');
  }

  const response = await fetch(`${config.SERVER_ADDRESS}${config.ACTIVATE_USER_VIA_TOKEN_PATH}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ token })
  });

  if (!response.ok) {
    throw new Error('Failed to reigster user via token');
  }

  return await response.json();
}
const registerUser = async (fullName: string, email: string, username: string, password: string) => {
  if (!fullName || !email || !username || !password) {
    throw new Error('parameters to registerUser cannot be null');
  }

  const response = await fetch(`${config.SERVER_ADDRESS}${config.REGISTER_USER_PATH}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ fullName, email, username, password })
  });

  if (!response.ok) {
    throw new Error('Failed to reigster user via token');
  }

  return await response.json();
}

const loginUser = async (email: string, password: string) => {
  if (!email || !password) {
    throw new Error('parameters to registerUser cannot be null');
  }

  const response = await fetch(config.SERVER_ADDRESS + config.LOGIN_PATH, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: new URLSearchParams({
      'email': email,
      'password': password,
    }).toString(),
    credentials: 'include'
  });

  if (!response.ok) {
    throw new Error('Login failed');
  }

  return await response.json();
}

const resetUserPassword = async (email: string) => {
  if (!email) {
    throw new Error('parameters to resetUserPassword cannot be null');
  }

  const response = await fetch(config.SERVER_ADDRESS + config.RESET_PASSWORD_PATH, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: new URLSearchParams({
      'email': email,
    }).toString(),
    credentials: 'include'
  });

  if (!response.ok) {
    throw new Error('Login failed');
  }

  return await response.json();
}

const downloadVideo = async (serializedContext: string) => {
  if (!serializedContext) {
    throw new Error('parameters to downloadVideo cannot be null');
  }

  const response = await fetch(`${config.SERVER_ADDRESS}${config.DOWNLOAD_VIDEO_PATH}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: serializedContext
  });

  if (!response.ok) {
    throw new Error('Failed to download video');
  }

  return await response.json();
}

const getSignedUploadUrl = async (fileName: string, fileType: string, sha256: string) => {
  if (!fileName) {
    throw new Error('parameters to downloadVideo cannot be null');
  }

  const response = await fetch(`${config.SERVER_ADDRESS}${config.GET_SIGNED_UPLOAD_URL_PATH}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ fileName, fileType, sha256 })
  });

  if (!response.ok) {
    throw new Error('Failed to download video');
  }

  return await response.json();
}

const getRenderedVideosInfo = async () => {
  const response = await fetch(`${config.SERVER_ADDRESS}${config.GET_RENDERED_VIDEOS_INFO_PATH}`, {
    method: 'GET',
    credentials: 'include',
  });

  if (!response.ok) {
    throw new Error('Failed to get rendered videos info');
  }

  return await response.json();
}

export {
  getSignedUploadUrl,
  getRenderedVideosInfo,
  downloadVideo,
  fetchIntegrations,
  fetchDestinations,
  fetchRemoteExports,
  fetchLocalExports,
  fetchRawData,
  deleteExport,
  deleteUser,
  deleteIntegration,
  updateProfile,
  registerViaToken,
  activateViaToken,
  registerUser,
  resendRegistration,
  loginUser,
  resetUserPassword,
  resetPasswordViaToken
};

