import React, { useState } from 'react';
import { Box, Card, IconButton, Typography } from '@mui/material';
import FiltersMenu from './FiltersMenu';
import EditorNavbar from './EditorNavbar';
import { ExpandLess, ExpandMore, ChevronLeft, ChevronRight } from '@mui/icons-material';

type EffectSelectorProps = {
    isMobileLayout: boolean;
    onSelect: (effectName: string) => void;
};

const mobileMenuSize = 300;
const desktopMenuSize = 400;

const EffectSelector: React.FC<EffectSelectorProps> = ({ isMobileLayout, onSelect }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeMenu, setActiveMenu] = useState<'filters' | 'text'>('filters');

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const handleFiltersClick = () => {
        setActiveMenu('filters');
        setIsOpen(true);
    };

    const handleTextClick = () => {
        setActiveMenu('text');
        setIsOpen(true);
    };

    return (
        <Box sx={{
            height: isMobileLayout ? 'auto' : '100%',
            width: isMobileLayout ? '100%' : 'auto',
            display: 'flex',
            flexDirection: isMobileLayout ? 'column' : 'row',
            position: 'relative',
            zIndex: 101,
            marginRight: isMobileLayout ? '0px' : '15px',
        }}>
            { // if desktop layout
                !isMobileLayout && (<EditorNavbar isMobileNavbar={isMobileLayout} onFiltersClicked={handleFiltersClick} onTextClicked={handleTextClick} />)
            }

            {/* Side Panel */}
            <Box sx={{ height: isMobileLayout ? '15px' : '100%', display: 'flex', position: 'relative' }}>
                <Card sx={{
                    direction: 'rtl',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    position: 'absolute',
                    bottom: '0px',
                    width: isMobileLayout ? '100%' : isOpen ? `${desktopMenuSize}px` : '0px',
                    height: isMobileLayout ? (isOpen ? `${mobileMenuSize}px` : '0px') : '100%',
                    margin: '0px',
                    transition: isMobileLayout ? 'height 0.3s ease' : 'width 0.3s ease',
                    borderTopRightRadius: '8px',
                    borderBottomRightRadius: '8px',
                    boxShadow: '0 2px 10px rgba(0,0,0,0.2)'
                }}>
                    {isOpen && (
                        <>
                            {activeMenu === 'filters' && <FiltersMenu onSelect={onSelect} />}
                            {activeMenu === 'text' && (
                                <Box>
                                    <Typography variant="h6">Text Menu</Typography>
                                    {/* Add text menu components here */}
                                </Box>
                            )}
                        </>
                    )}
                </Card>

                {/* Custom Handle */}
                <Box
                    onClick={toggleOpen}
                    sx={{
                        width: isMobileLayout ? '100%' : '15px',
                        height: isMobileLayout ? '15px' : '100%',
                        position: 'absolute',
                        bottom: isMobileLayout ? isOpen ? `${mobileMenuSize}px` : '0px' : '0px',
                        left: isMobileLayout ? '0px' : isOpen ? `${desktopMenuSize}px` : '0px',
                        backgroundColor: '#eeeeee',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderTopRightRadius: '8px',
                        borderBottomRightRadius: '8px',
                        transition: isMobileLayout ? 'bottom 0.3s ease' : 'left 0.3s ease',
                        zIndex: 2,
                    }}
                >
                    <IconButton>
                        {isMobileLayout ? (isOpen ? <ExpandMore /> : <ExpandLess />) : (isOpen ? <ChevronLeft /> : <ChevronRight />)}
                    </IconButton>
                </Box>
            </Box>
            
            { // if mobile layout
                isMobileLayout && (<EditorNavbar isMobileNavbar={isMobileLayout} onFiltersClicked={handleFiltersClick} onTextClicked={handleTextClick} />)
            }

        </Box>
    );
};

export default EffectSelector;
