import { effectNameToStringMap } from './Effect';
import getEngine from './EffectsEngine';
import Media from './Media';
import { v4 as uuidv4 } from 'uuid';

const Engine = await getEngine();

type shrinkType = 'shrinkLeft' | 'shrinkRight' | 'increaseLeft' | 'increaseRight' | null;

type Crop = {
  left: number,
  right: number,
  top: number,
  bottom: number
}

export default class Video extends Media {
  id: string;
  sha256: string;
  name: string;
  type: string;
  url: string;
  storageFilePath: string;
  storageBuffer: Uint8Array | null;
  duration: number;
  left: number;
  leftBackgroundOffset: number;
  rightBackgroundOffset: number;
  startBase: number;
  endBase: number;
  isResizing: boolean;
  leftShrink: number;
  rightShrink: number;
  videoRef: any;
  videoFdRef: number | null;
  isPlaying: boolean;
  framePendingTime: number | null;
  playOffset: number;
  framesOffset: number;
  curVideoTime: number;
  row: null | number;
  effects: string[];
  dirty: boolean;
  height: number;
  width: number;
  aspectRatio: number;
  scaledHeight: number | null;
  scaledWidth: number | null;
  baseHeight: number;
  baseWidth: number;
  frames: any;
  x: number;
  y: number;
  rotation: number;
  crop: Crop;
  onLoadedCalled: boolean;
  hasVideoComponent: boolean;
  hasAudioComponent: boolean;
  uploadProgress: number;

  // Constructor using named parameters
  constructor({
    id,
    sha256,
    name,
    type,
    url,
    storageFilePath,
    storageBuffer = null,
    duration,
    left = 0,
    leftBackgroundOffset = 0,
    rightBackgroundOffset = 0,
    startBase = 0,
    endBase,
    isResizing = false,
    leftShrink = 0,
    rightShrink = 0,
    videoRef = null,
    videoFdRef = null,
    isPlaying = false,
    framePendingTime = null,
    playOffset = 0,
    framesOffset = 0,
    curVideoTime = 0,
    row = null,
    effects = [],
    dirty = false,
    height,
    width,
    scaledHeight = null,
    scaledWidth = null,
    baseHeight = 0,
    baseWidth = 0,
    x,
    y,
    rotation,
    crop,
    onLoadedCalled = false,
    hasVideoComponent = false,
    hasAudioComponent = false,
    uploadProgress = 0,
  }: {
    id?: string;
    sha256: string;
    name: string;
    type: string;
    url: string;
    storageFilePath: string;
    storageBuffer?: Uint8Array | null;
    duration: number;
    left?: number;
    leftBackgroundOffset?: number;
    rightBackgroundOffset?: number;
    startBase?: number;
    endBase: number;
    isResizing?: boolean;
    leftShrink?: number;
    rightShrink?: number;
    videoRef?: any;
    videoFdRef?: number | null;
    isPlaying?: boolean;
    framePendingTime?: number | null;
    playOffset?: number;
    framesOffset?: number;
    curVideoTime?: number;
    row?: number | null;
    effects?: string[];
    dirty?: boolean;
    height: number;
    width: number;
    scaledHeight: number | null;
    scaledWidth: number | null;
    baseHeight: number;
    baseWidth: number;
    x: number;
    y: number;
    rotation: number;
    crop: Crop;
    onLoadedCalled?: boolean;
    hasVideoComponent?: boolean;
    hasAudioComponent?: boolean;
    uploadProgress?: number;
  }) {
    super();
    // always create new to prevent two videos using the same storage
    let newStorage;
    if (storageBuffer) {
      // TODO: should we copy the memory?
      newStorage = new Uint8Array(storageBuffer.length);
    } else {
      newStorage = new Uint8Array();
    }
    this.id = id ? id : uuidv4();
    this.sha256 = sha256;
    this.name = name;
    this.url = url;
    this.type = type;
    this.storageFilePath = storageFilePath;
    this.storageBuffer = newStorage;
    this.duration = duration;
    this.left = left;
    this.leftBackgroundOffset = leftBackgroundOffset;
    this.rightBackgroundOffset = rightBackgroundOffset;
    this.startBase = startBase;
    this.endBase = endBase;
    this.isResizing = isResizing;
    this.leftShrink = leftShrink;
    this.rightShrink = rightShrink;
    this.videoRef = videoRef;
    this.videoFdRef = videoFdRef;
    this.isPlaying = isPlaying;
    this.framePendingTime = framePendingTime;
    this.playOffset = playOffset;
    this.framesOffset = framesOffset;
    this.curVideoTime = curVideoTime;
    this.row = row;
    this.effects = effects;
    this.dirty = dirty;
    this.height = height;
    this.width = width;
    this.aspectRatio = width / height;
    this.scaledHeight = scaledHeight;
    this.scaledWidth = scaledWidth;
    this.baseHeight = baseHeight;
    this.baseWidth = baseWidth;
    this.frames = [];
    this.x = x;
    this.y = y;
    this.rotation = rotation;
    this.crop = {... crop};
    this.onLoadedCalled = onLoadedCalled;
    this.hasVideoComponent = hasVideoComponent;
    this.hasAudioComponent = hasAudioComponent;
    this.uploadProgress = uploadProgress;
  }

  hasVideo = (): boolean => {
    return this.hasVideoComponent;
  }

  hasAudio = (): boolean => {
    return this.hasAudioComponent;
  }

  // Method to add an effect to the video
  addEffect = async (effect: string): Promise<boolean> => {
    if (effect in effectNameToStringMap) {
      const originalEffects = [...this.effects];
      const index = this.effects.indexOf(effect);
      if (index === -1) {
        this.effects.push(effect);
      } else {
        // remove the effect
        this.effects.splice(index, 1);
      }

      const effectString = this.getEffectString();
      const res = await Engine.set_effect(this.videoFdRef as number, this.scaledWidth as number, this.scaledHeight as number, effectString).then((res: number) => {
        return res;
      });

      if (res == -1) {
        console.error("failed to set effect");
        return false;
      } 
      return true;
    }

    return false;
  }

  getEffectString = (prependVideoNormalization: boolean = false): string => {
    const cropOutWOffset = this.crop.right + this.crop.left;
    let cropWString = '';
    if (cropOutWOffset !== 0) {
      cropWString = cropOutWOffset > 0 ? `+${cropOutWOffset}` : `${cropOutWOffset}`;
    }
    const cropOutHOffset = this.crop.top + this.crop.bottom;
    let cropHString = '';
    if (cropOutHOffset !== 0) {
      cropHString = cropOutHOffset > 0 ? `+${cropOutHOffset}` : `${cropOutHOffset}`;
    }

    // TODO: fix that scaledHeight and scaledWidth are not null
    let cropLeft = 0;
    let cropRight = 0;
    let cropTop = 0;
    let cropBottom = 0;
    if (this.scaledHeight && this.scaledWidth) {
      cropLeft = Math.ceil(this.crop.left * this.scaledWidth);
      cropRight = Math.ceil(this.crop.right * this.scaledWidth);
      if (prependVideoNormalization) {
        cropBottom = Math.ceil(this.crop.top * this.scaledHeight);
        cropTop = Math.ceil(this.crop.bottom * this.scaledHeight);
      } else{
        cropBottom = Math.ceil(this.crop.bottom * this.scaledHeight);
        cropTop = Math.ceil(this.crop.top * this.scaledHeight);
      }
    }

    return (
      (prependVideoNormalization ? [`scale=${this.scaledWidth}:${this.scaledHeight}`] : [])
      .concat(
      //this.rotation ? [`rotate=${this.rotation}*PI/180:ow=rotw(iw):oh=roth(ih):c=black, pad='iw*sqrt(2)':'ih*sqrt(2)':(ow-iw)/2:(oh-ih)/2`] : [],
      this.rotation ? [`rotate=${this.rotation}*PI/180`] : [],
      (this.crop.top || this.crop.bottom || this.crop.left || this.crop.right) ?
        [`crop=${(Math.max((this.scaledWidth || 0)-cropRight-cropLeft, 1))}:${Math.max((this.scaledHeight || 0)-cropTop-cropBottom, 1)}:${cropLeft}:${cropBottom}`] :
        [],
      this.effects.map((effectName: string) => effectNameToStringMap[effectName]),
      // scale the image to the current size at the end
      `scale=${this.scaledWidth}:${this.scaledHeight}:flags=fast_bilinear`
      )
      .join(','));
  }

  setCrop = async (direction: 'left' | 'right' | 'top' | 'bottom', offset: number) => {
    this.crop[direction] = offset;
    const effectString = this.getEffectString();
    const res = await Engine.set_effect(this.videoFdRef as number, this.scaledWidth as number, this.scaledHeight as number, effectString).then((res: number) => {
      return res;
    });
    return res;
  }

  setRotation = async (degrees: number) => {
    this.rotation = degrees;

    //const angleInRadians = degrees * Math.PI / 180;
    //const newWidth = Math.abs(this.scaledWidth * Math.cos(angleInRadians)) + Math.abs(this.scaledHeight * Math.sin(angleInRadians));
    //const newHeight = Math.abs(this.scaledWidth * Math.sin(angleInRadians)) + Math.abs(this.scaledHeight * Math.cos(angleInRadians));
    //this.scaledWidth = newWidth;
    //this.scaledHeight = newHeight;
    const effectString = this.getEffectString();
    const res = await Engine.set_effect(this.videoFdRef as number, this.scaledWidth as number, this.scaledHeight as number, effectString).then((res: number) => {
      return res;
    });
    return res;
  }

  deepCopy = (): Video => {
    let newStorage;
    if (this.storageBuffer) {
      // TODO: should we copy the memory?
      newStorage = new Uint8Array(this.storageBuffer.length);
    } else {
      newStorage = new Uint8Array();
    }
    return new Video({
      id: this.id,
      sha256: this.sha256,
      name: this.name,
      type: this.type,
      url: this.url,
      storageFilePath: this.storageFilePath,
      storageBuffer: newStorage,
      duration: this.duration,
      left: this.left,
      leftBackgroundOffset: this.leftBackgroundOffset,
      rightBackgroundOffset: this.rightBackgroundOffset,
      startBase: this.startBase,
      endBase: this.endBase,
      isResizing: this.isResizing,
      leftShrink: this.leftShrink,
      rightShrink: this.rightShrink,
      videoRef: this.videoRef,
      videoFdRef: this.videoFdRef,
      isPlaying: this.isPlaying,
      framePendingTime: this.framePendingTime,
      playOffset: this.playOffset,
      framesOffset: this.framesOffset,
      curVideoTime: this.curVideoTime,
      row: this.row,
      effects: [...this.effects],
      dirty: this.dirty,
      height: this.height,
      width: this.width,
      scaledHeight: this.scaledHeight,
      scaledWidth: this.scaledWidth,
      baseHeight: this.baseHeight,
      baseWidth: this.baseWidth,
      x: this.x,
      y: this.y,
      rotation: this.rotation,
      crop: { left: this.crop.left, right: this.crop.right, top: this.crop.top, bottom: this.crop.bottom },
      hasVideoComponent: this.hasVideoComponent,
      hasAudioComponent: this.hasAudioComponent,
      uploadProgress: this.uploadProgress,
    })
  }

  getSerializable = (): Video => {
    const cleanVideo = this.deepCopy();
    cleanVideo.storageBuffer = null;
    cleanVideo.videoRef = null;
    return cleanVideo;
  }
}

export {
  type shrinkType
}