import React, { useState, useRef, useEffect, MouseEvent as ReactMouseEvent } from 'react';
import { Box } from '@mui/material';
import { VideoFramesComponent } from './VideoFramesComponent';
import RGL, { WidthProvider, Layout } from 'react-grid-layout';
import { FrameSlider } from './FrameSlider';
import CommandHistory, { ResizeVideoCommand, DeleteVideoCommand, AddVideoCommand } from './undo/UndoInterface';
import { calculateSha256 } from '../utils/utils';
import { v4 as uuidv4 } from 'uuid';
import { CloudUpload } from '@mui/icons-material';
import { createVideoFd } from '../utils/utils';

import RightClickMenu from './RightClickMenu';
import VideoEditorControls from './VideoEditorControls';
import getEngine from './EffectsEngine';
import Video from './Video';

import 'react-resizable/css/styles.css';
import './VideoLayers.css';

const Engine = await getEngine();

const ResponsiveGridLayout = WidthProvider(RGL);

interface CustomHandleProps {
    axis: 'w' | 'e';
    direction: 'left' | 'right';
    onResizeHandleMouseDown: (event: ReactMouseEvent<HTMLSpanElement>, axis: 'w' | 'e') => void;
}

// Custom handle component with types
const CustomHandle: React.FC<CustomHandleProps> = ({ axis, direction, onResizeHandleMouseDown }) => {
    return (
        <span
            className={`custom-handle custom-handle-${direction} react-resizable-handle react-resizable-handle-${axis}`}
            onMouseDown={(e: ReactMouseEvent<HTMLSpanElement>) => onResizeHandleMouseDown(e, axis)}
        />
    );
};

interface VideoLayersProps {
  currentTime: number;
  isRunning: boolean;
  onVideoAdded: (file: Video) => void;
  onLayersChanged: (layers: any[], totalDuration: number) => void;
  onSliderChange: (event: Event, newValue: number | number[]) => void;
  onLayerSelected: (layer: Video) => void;
  handlePlayPause: () => void;
  handleSkipFrame: (direction: 'forward' | 'backward') => void;
  selectedVideoId: string | undefined;
  isMobile: boolean;
}

const VideoLayers: React.FC<VideoLayersProps> = ({
    currentTime, isRunning, onVideoAdded, onLayersChanged, onSliderChange, onLayerSelected, handlePlayPause, handleSkipFrame, selectedVideoId, isMobile
  }) => {
  const [videos, setVideos] = useState<Video[]>([]);
  const [totalDuration, setTotalDuration] = useState(0);
  const [maxSliderOffset, setMaxSliderOffset] = useState(0);
  const [columnWidth, setColumnWidth] = useState(0);
  const [layout, setLayout] = useState<Layout[]>([]);
  const [selectedVideoIndex, setSelectedVideoIndex] = useState<number | null>(null);
  const [activeHandleDirection, setActiveHandleDirection] = useState<'w' | 'e' | null>(null);
  const [isLoadingVideo, setIsLoadingVideo] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState(false);
  const [menuPosition, setMenuPosition] = useState<{ mouseX: number | null; mouseY: number | null }>({
    mouseX: null,
    mouseY: null,
  });
  const [menuOpen, setMenuOpen] = useState(false);
  const [gridWidth, setGridWidth] = useState<number>(0);


  const beforeResizeVideosRef = useRef<any[]>([]);
  const dragRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<any>(null);
  const containerRef = useRef<any>(null);
  const zoomFactor = useRef<number>(1);
  const numColumns = 5192;
  const numFrames = 20;

  const calculateColumnWidth = () => {
    if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const widthPerColumn = containerWidth / numColumns;
        setColumnWidth(widthPerColumn);
    }
  };

  const handleDragEnter = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setIsDragging(true);
  };

  const handleDragLeave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  useEffect(() => {
      const observer = new ResizeObserver(() => {
        calculateColumnWidth();
      });

      if (containerRef.current) {
        observer.observe(containerRef.current);
      }

      // Add event listeners to the resize handles
      const leftHandles = document.querySelectorAll('.react-resizable-handle-w');
      leftHandles.forEach((handle: any) => {
          handle.addEventListener('mousedown', () => setActiveHandleDirection('w'));
      });

      const rightHandles = document.querySelectorAll('.react-resizable-handle-e');
      rightHandles.forEach((handle: any) => {
          handle.addEventListener('mousedown', () => setActiveHandleDirection('e'));
      });

      window.addEventListener('dragenter', handleDragEnter);
      window.addEventListener('drop', handleDrop);


      return () => {
        window.removeEventListener('resize', calculateColumnWidth);
        window.removeEventListener('dragenter', handleDragEnter);
        window.removeEventListener('drop', handleDrop);
      };
  }, [layout]);

  useEffect(()=> {
  }, [isRunning])
  useEffect(() => {
  }, [currentTime])
  useEffect(() => {
  }, [isMobile])

  useEffect(() => {
    const selectedIndex = videos.findIndex((video: Video) => video.id === selectedVideoId) ;
    if (selectedIndex !== -1) {
      setSelectedVideoIndex(selectedIndex);
    }
  }, [selectedVideoId])

  const handleFileDrop = async (file: File) => {
    if (isLoadingVideo) {
      // we currently do not support loading videos at the same time
      return;
    }

    // TODO: for now disable this until we have the feature ready
    //if (file && (file.type.startsWith('video/') || file.type.startsWith('audio/'))) {
    if (file && file.type.startsWith('video/')) {
      setIsLoadingVideo(true);
      const videoURL = URL.createObjectURL(file);

      const sha256 = await calculateSha256(file);

      const video = videoRef.current;
      video.src = videoURL;
      video.name = file.name;

      video.onloadedmetadata = async () => {

        const duration = video.duration;
        const row = videos.reduce((max: number, v: any) => v.row > max ? v.row : max, -1) + 1;
        const storageFilePath = videoURL.split('/').splice(-1) + '.bmp';
        const newVideoObject: Video = new Video({name: video.name, videoRef: null, videoFdRef: null, framePendingTime: null, url: videoURL, duration, left: 0, leftBackgroundOffset: 0, rightBackgroundOffset: 0,
          startBase: 0, endBase: 0, isResizing: false, leftShrink: 0, rightShrink: 0, row: row, framesOffset: 0, playOffset: 0,
          effects: [], isPlaying: false, curVideoTime: 0, dirty: false, height: video.videoHeight, width: video.videoWidth,
          storageFilePath: storageFilePath, storageBuffer: null, scaledHeight: null, scaledWidth: null, baseWidth: 0, baseHeight: 0,
          x: 0, y: 0, rotation: 0, type: file.type, crop: {left: 0, right: 0, top: 0, bottom: 0}, sha256: sha256,
        });

        const fd = await createVideoFd(newVideoObject);

        newVideoObject.videoFdRef = fd;
        const hasVideoComponent = file.type.startsWith('video/');
        const hasAudioComponent = await Engine.has_audio(fd);
        newVideoObject.hasAudioComponent = hasAudioComponent;
        newVideoObject.hasVideoComponent = hasVideoComponent;

        const newVideos = [...videos, newVideoObject];
        const maxDuration = Math.max(...newVideos.map((video) => video.duration));
        // normalize all video attributes according to the scale change due to changing the maxDuration (the scale of the movies is according to max movie)
        const normalizedVideos = newVideos.map((video: Video) => {
            const normalizer = video.duration / maxDuration;
            return new Video({
                ...video,
                left:video.left * normalizer,
                leftBackgroundOffset: video.leftBackgroundOffset * normalizer,
                rightBackgroundOffset: video.rightBackgroundOffset * normalizer,
                startBase: video.startBase * normalizer,
                endBase: video.endBase * normalizer,
                leftShrink: video.leftShrink * normalizer,
                rightShrink: video.rightShrink * normalizer
            })
        })

        const originalVideos = videos.map((vid: Video) => vid.deepCopy());
        const addVideosCommand = new AddVideoCommand(originalVideos as Video[], normalizedVideos, newVideos.length-1, (newVideos: Video[]) => {
          setVideos(newVideos)
        });

        CommandHistory.push(addVideosCommand);

        setVideos(normalizedVideos);
        setTotalDuration(maxDuration);
      };
    }
  };

  const handleResizeStart = (layout: any, oldItem: any, newItem: any, placeholder: any, e: any) => {
    const index = Number(oldItem.i);
    if (videos[index].isResizing) {
        console.error("resizing while still in resizing!")
    }

    beforeResizeVideosRef.current = videos.map((vid: Video) => vid.deepCopy());

    const newVideos = [...videos];
    newVideos[index].isResizing = true;
    setVideos(newVideos);
  }

  const getAdjacentLayoutItem = (item: any, direction: 'left' | 'right') => {
    let foundItem: any = null;
    for (const candidate of layout) {
      if (candidate.i == item.i || candidate.y != item.y) {
        continue;
      }
      if (direction == 'left') {
        const candidateRight = candidate.x + candidate.w;
        if (candidateRight <= item.x && (!foundItem || candidateRight >= foundItem.x + foundItem.w) ) {
          foundItem = candidate;
        }
      } else {
        const candidateLeft = candidate.x;
        if (candidateLeft >= item.x + item.w && (!foundItem || candidateLeft <= foundItem.x) ) {
          foundItem = candidate;
        }
      }
    }

    return foundItem;
  }

  const getLayoutItemToTheRight = (item: any) => {
    return getAdjacentLayoutItem(item, 'right');
  }

  const getLayoutItemToTheLeft = (item: any) => {
    return getAdjacentLayoutItem(item, 'left');
  }

  const calculateOffsets = (oldItem: any, newItem: any, isFinalResize: boolean = false) => {
    const index = Number(oldItem.i);

    const direction = activeHandleDirection;

    let leftBackgroundOffset = null;
    let rightBackgroundOffset = null;

    const increaseAmount = newItem.w - oldItem.w;

    // the right side
    if (activeHandleDirection == 'e') {
        if (increaseAmount > videos[index].rightShrink) {
            rightBackgroundOffset = 0;
            newItem.maxW = oldItem.w + videos[index].rightShrink;
        } else {
            rightBackgroundOffset = oldItem.w - newItem.w + videos[index].endBase
            if (!isFinalResize) {
              const item = getLayoutItemToTheRight(oldItem);
              if (item) {
                const itemLeft = item.x;
                const oldItemRight = oldItem.x + oldItem.w;
                // cant increase further than adjacent element
                newItem.maxW = Math.min(oldItem.w + (itemLeft - oldItemRight), oldItem.w + videos[index].rightShrink);
              }
            } else {
              newItem.maxW = oldItem.w + videos[index].rightShrink + videos[index].leftShrink;
            }
        }
    }
    // the left side
    else {
        if (increaseAmount > videos[index].leftShrink) {
            leftBackgroundOffset = 0;
            newItem.maxW = oldItem.w + videos[index].leftShrink;
        } else {
            leftBackgroundOffset = newItem.w - oldItem.w + videos[index].startBase;
            if (!isFinalResize) {
              const item = getLayoutItemToTheLeft(oldItem);
              if (item) {
                const itemRight = item.x + item.w;
                // cant increase further than adjacent element
                newItem.maxW = Math.min(oldItem.w + (oldItem.x - itemRight), oldItem.w + videos[index].leftShrink);
              }
            } else {
              newItem.maxW = oldItem.w + videos[index].rightShrink + videos[index].leftShrink;
            }
        }
    }

    return [leftBackgroundOffset, rightBackgroundOffset];
  }
  const handleResizeStop = (layout: any, oldItem: any, newItem: any) => {
    const index = Number(oldItem.i);
    if (!videos[index].isResizing) {
        console.error("resizing without having isResizing enabled")
        newItem.w = oldItem.w;
        newItem.x = oldItem.x;
        return;
    }

    const [leftBackgroundOffset, rightBackgroundOffset] = calculateOffsets(oldItem, newItem, true);
    const newVideos = [...videos];
    if (leftBackgroundOffset !== null) {
        newVideos[index].leftBackgroundOffset = (leftBackgroundOffset);
        newVideos[index].startBase = leftBackgroundOffset;
        // shrinked from left
        if (oldItem.x < newItem.x && oldItem.w > newItem.w) {
            newVideos[index].leftShrink = -leftBackgroundOffset;
            newVideos[index].left = newItem.x;
        }
        // increase the left side
        if (newItem.x < oldItem.x && newItem.w > oldItem.w) {
            newVideos[index].leftShrink = -leftBackgroundOffset;
            newVideos[index].left = newItem.x;
        }
    }
    if (rightBackgroundOffset !== null) {
        console.log(rightBackgroundOffset);
        newVideos[index].rightBackgroundOffset = (rightBackgroundOffset);
        newVideos[index].endBase = rightBackgroundOffset;
        console.log(rightBackgroundOffset);
        // shrinked from right
        if (oldItem.x == newItem.x && oldItem.w > newItem.w) {
            newVideos[index].rightShrink = rightBackgroundOffset;
        }
        // increase the right side
        if (newItem.x == oldItem.x && newItem.w > oldItem.w) {
            newVideos[index].rightShrink = rightBackgroundOffset;
        }
    }

    newVideos[index].isResizing = false;

    // TODO: really don't like this - part of playing logic in a resize callback
    newVideos[index].playOffset = (newVideos[index].leftShrink / numColumns) * totalDuration + newVideos[index].framesOffset;
    // set as dirty so it gets seeked to the offset;
    newVideos[index].dirty = true;

    const resizeCommand = new ResizeVideoCommand(beforeResizeVideosRef.current as Video[], newVideos, (newVideos: Video[]) => {
      setVideos(newVideos)
    });

    CommandHistory.push(resizeCommand);

    setVideos(newVideos);
  }

  const handleResize = (layout: any, oldItem: any, newItem: any, placeholder: any, e: any) => {
    const [leftBackgroundOffset, rightBackgroundOffset] = calculateOffsets(oldItem, newItem);
    if (leftBackgroundOffset === null && rightBackgroundOffset === null) {
        newItem.x = oldItem.x;
        newItem.w = oldItem.w;
        return;
    }

    const index = Number(oldItem.i);
    const newVideos = [...videos];
    if (leftBackgroundOffset !== null) {
        newVideos[index].leftBackgroundOffset = (leftBackgroundOffset);
    }
    if (rightBackgroundOffset !== null) {
        newVideos[index].rightBackgroundOffset = (rightBackgroundOffset);
    }

    setVideos(newVideos);
  };

  const handleDragStart = (layout: any, oldItem: any, newItem: any, placeholder: any, e: any) => {
    beforeResizeVideosRef.current = videos.map((vid: Video) => vid.deepCopy());
  }

  const handleDragStop = (layout: any, oldItem: any, newItem: any) => {
    const newVideos = [...videos];
    const index = Number(oldItem.i);
    newVideos[index].left = (newItem.x);
    newVideos[index].row = (newItem.y);

    const resizeCommand = new ResizeVideoCommand(beforeResizeVideosRef.current as Video[], newVideos, (newVideos: Video[]) => {
      setVideos(newVideos)
    });

    CommandHistory.push(resizeCommand);

    setVideos(newVideos);
  }

  const calculateConversionMetadata = (video: Video) => {
    const playCut = (video.leftShrink / numColumns) * totalDuration + (video.rightShrink / numColumns) * totalDuration;
    const videoStart = (video.left / numColumns) * totalDuration;
    const videoEnd = videoStart + video.duration - playCut;
    return {
      video: video,
      rowNumber: video.row,
      playOffset: video.playOffset,
      start: videoStart,
      end: videoEnd,
      effects: video.effects
    };
  }

  const convertLayoutToLayers = (layout: Layout[]) => {
    return layout.map((l: Layout) => calculateConversionMetadata(videos[Number(l.i)]));
  }

  const handleLayoutChange = (currentLayout: Layout[]) => {
    setLayout(currentLayout);
    const layers = convertLayoutToLayers(currentLayout);
    const maxVideoEnd = Math.max(...layers.map((layer) => layer.end));
    setMaxSliderOffset(maxVideoEnd);

    onLayersChanged(layers, maxVideoEnd);
  }

  const onLoadedVideo = (video: Video, index: number) => {
    setIsLoadingVideo(false);
    const isNew = !videos[index].onLoadedCalled;
    video.onLoadedCalled = isNew;

    const newVideos = [...videos];
    newVideos[index] = video;
    setVideos(newVideos)
    if (isNew) {
      // the videoRef starts with null and added inside the component
      // so if it's null, it is a new video
      onVideoAdded(video);
    }
  }

  const handleDelete = async () => {
    if (selectedVideoIndex === null) {
      return;
    }
    const fd = videos[selectedVideoIndex].videoFdRef;
    if (fd !== null) {
      Engine.close_movie([(fd as unknown) as string]).then((res: number) => {
        if (res === -1) {
          console.error('failed to close movie');
        }
      })
    }
    const updatedVideos = [...videos];
    updatedVideos.splice(selectedVideoIndex, 1);

    const originalVideos = videos.map((vid: Video) => vid.deepCopy());
    const deleteVideosCommand = new DeleteVideoCommand(originalVideos as Video[], updatedVideos, selectedVideoIndex, (newVideos: Video[]) => {
      setVideos(newVideos)
    });

    CommandHistory.push(deleteVideosCommand);
    setSelectedVideoIndex(null);

    setVideos(updatedVideos);
  }

  const handleRemoveSound = async () => {
    if (selectedVideoIndex === null) {
      return;
    }
    const newVideos = [...videos];
    newVideos[selectedVideoIndex].hasAudioComponent = false;
    newVideos[selectedVideoIndex].videoRef.volume = 0;
    setVideos(newVideos);
  }

  const handleCut = async (cutPosition: number) => {
    if (selectedVideoIndex === null) {
      return;
    }
    const updatedVideos = [...videos];

    const index = selectedVideoIndex;
    const video = videos[selectedVideoIndex];
    const videoStart = video.left;
    const videoEnd = video.left + (video.duration / totalDuration) * numColumns;
    // If the cutPosition is within the video bounds
    if (cutPosition > videoStart && cutPosition < videoEnd) {
      const firstPartDuration = (cutPosition - (videoStart - video.leftShrink)) / numColumns * totalDuration;
      const secondPartDuration = video.duration - firstPartDuration;

      const firstVideo: Video = new Video({
        ...video.deepCopy(),
        playOffset: video.playOffset,
        framesOffset: video.framesOffset,
        duration: firstPartDuration,
        endBase: 0,
        rightBackgroundOffset: 0,
        rightShrink: 0,
        row: video.row,
        dirty: true
      });

      const secondVideo: Video = new Video({
        ...video.deepCopy(),
        id: uuidv4(),
        left: cutPosition,
        playOffset: video.playOffset + firstPartDuration,
        framesOffset: video.framesOffset + firstPartDuration,
        duration: secondPartDuration,
        startBase: 0,
        leftBackgroundOffset: 0,
        leftShrink: 0,
        row: video.row,
        dirty: true
      });

      const fd = await createVideoFd(secondVideo);
      if (fd !== -1) {
        secondVideo.videoFdRef = fd;
        const effectString = secondVideo.getEffectString();
        await Engine.set_effect(fd, secondVideo.scaledWidth as number, secondVideo.scaledHeight as number, effectString)
      }

      updatedVideos[index] = firstVideo;
      updatedVideos.push(secondVideo);
    }

    const originalVideos = videos.map((vid: Video) => vid.deepCopy());
    const resizeCommand = new ResizeVideoCommand(originalVideos as Video[], updatedVideos, (newVideos: Video[]) => {
      setVideos(newVideos)
    });
    CommandHistory.push(resizeCommand);

    setVideos(updatedVideos);
  };

  const handleVideoSelect = (event: any, index: number) => {
    setSelectedVideoIndex(index);
    onLayerSelected(videos[index]);
  };

  const onSkipFrameClicked = (direction: 'forward' | 'backward'): void => {
    if (!isLoadingVideo) {
      handleSkipFrame(direction);
    }
  }

  const handleZoomChange = (zoomValue: number) => {
    setGridWidth(Math.max(window.innerWidth * zoomValue, window.innerWidth));
  }

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setMenuPosition({
      mouseX: event.clientX,
      mouseY: event.clientY,
    });
    setMenuOpen(true);
  };

  const handleClose = () => {
    setMenuOpen(false);
  };

  const handleOption1 = () => {
    console.log('Option 1 selected');
  };

  const handleOption2 = () => {
    console.log('Option 2 selected');
  };

  const handleOption3 = () => {
    console.log('Option 3 selected');
  };

  const gridRef = useRef<any>(null);

  return (
    <Box sx={{height: '100%', position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'start', width: '100%', overflow: 'auto'}}>
      <VideoEditorControls
        currentTime={currentTime}
        maxZoom={totalDuration}
        isMobileLayout={isMobile}
        isRunning={isRunning}
        handleAdd={handleFileDrop}
        handleCut={() => handleCut(Math.floor((currentTime / totalDuration) * numColumns))}
        handleDelete={handleDelete}
        handleZoomChange={handleZoomChange}
        handleRemoveSound={handleRemoveSound}
        handlePlayPause={handlePlayPause}
        handleSkipFrame={onSkipFrameClicked}
      />
      <Box sx={{ height: '100%', overflowX:'auto', overflowY: 'hidden'}}>
        <Box
            ref={containerRef}
            onDragOver={(e) => e.preventDefault()}
            onDrop={(event: React.DragEvent<HTMLDivElement>) => {
              event.preventDefault();
              handleFileDrop(event.dataTransfer.files[0])
            }}
            sx={{
                display: 'flex',
                userSelect: 'none',
                flexDirection: 'column',
                justifyContent: 'space-around',
                width: gridWidth || '100%',
                height: '100%',
                overflowY: 'hidden',
                overflowX: 'auto',
            }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, backgroundColor: "#f8f8f8", overflowY: 'hidden', overflowX: 'auto'}}>
            <Box sx={{width: '100%', height: '40px'}}>
              <FrameSlider
                isThumbVisible={true}
                value={currentTime}
                onChange={onSliderChange}
                min={0}
                totalDuration={totalDuration || 60}
                maxSliderOffset={maxSliderOffset}
                step={0.02} // Adjust the step based on how fine-grained you want the control
                markerGap={50}
                aria-labelledby="video-slider"
              />
            </Box>
            <Box
              onContextMenu={handleContextMenu}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexGrow: 1,
                overflowX: 'hidden',
                overflowY: 'auto',
                width: '100%'
                }}
              >
              <ResponsiveGridLayout
                ref={gridRef}
                className="layout"
                cols={numColumns}
                rowHeight={60}
                preventCollision={true}
                resizeHandles={["e", "w"]}
                compactType={null}
                isDraggable={true}
                margin={[0, 2]}
                onDragStart={handleDragStart}
                onDragStop={handleDragStop}
                onResizeStop={handleResizeStop}
                onResize={handleResize}
                onResizeStart={handleResizeStart}
                onLayoutChange={handleLayoutChange}
                style={{position:'relative', width: gridWidth || '100%'}}
                draggableHandle=".drag-handle"
              >
                {videos.map((video: Video, index: number) => (
                  <Box
                    className="drag-handle"
                    key={index}
                    data-grid={{
                      i: index,
                      x: video.left,
                      y: video.row,
                      w: (video.duration / totalDuration) * numColumns - video.leftShrink - video.rightShrink,
                      h: 1,
                      minW: 1,
                      maxW: (video.duration / totalDuration) * numColumns,
                      isBounded: true,
                      isDraggable: isMobile ? selectedVideoIndex === index : true // for mobile, only allow dragging once selected. for desktop can drag at all times
                    }}
                    sx={{
                      overflow: video.isResizing ? 'visible' : 'hidden',
                      border: selectedVideoIndex === index ? '2px solid #1976d2' : '1px solid transparent',
                      boxShadow: selectedVideoIndex === index ? '0px 0px 10px rgba(25, 118, 210, 0.8)' : 'none',
                    }}
                  >
                    <div
                      onClick={(event: any) => handleVideoSelect(event, index)}
                      onTouchStart={(event: any) => handleVideoSelect(event, index)}
                      onMouseDown={(event: any) => handleVideoSelect(event, index)}
                    >
                      {/*
                      <Box sx={{zIndex: 999,position: 'absolute', width: `${video.uploadProgress}%`, height: '100%', backgroundColor: 'red'}}>
                        {video.uploadProgress}
                      </Box>
                      */}
                      <VideoFramesComponent
                        video={video}
                        numFrames={Math.ceil(numFrames * (video.duration / totalDuration))}
                        leftBackgroundOffset={video.leftBackgroundOffset * columnWidth}
                        rightBackgroundOffset={video.rightBackgroundOffset * columnWidth}
                        size={(video.duration / totalDuration) * numColumns * columnWidth}
                        hasVideoComponent={video.hasVideo()}
                        hasAudioComponent={video.hasAudio()}
                        onLoadedVideo={(vid: Video) => onLoadedVideo(vid, index)}
                      />
                    </div>
                  </Box>
                ))}
              </ResponsiveGridLayout>
            </Box>
          </Box>
          <video ref={videoRef} style={{ display: 'none' }} />
          {/*
          <RightClickMenu
            open={menuOpen}
            position={menuPosition}
            onClose={handleClose}
            onOption1={handleOption1}
            onOption2={handleOption2}
            onOption3={handleOption3}
          />
          */}
          {isDragging && (
            <div
              ref={dragRef}
              onDragLeave={handleDragLeave}
              className="drop-overlay"
            >
              <div className="drop-content" style={{pointerEvents: 'none'}}>
                <CloudUpload className="upload-icon" style={{fontSize: 120, pointerEvents: 'none'}} />
                <h2 className="drop-title" style={{pointerEvents: 'none'}}>Drop your file here</h2>
                <p className="drop-subtitle" style={{pointerEvents: 'none'}}>or drag and drop anywhere on the screen</p>
              </div>
            </div>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export { VideoLayers };
