import { FFmpeg } from '@ffmpeg2/ffmpeg2';
import config from '../constants/constants';

import './EngineLoader';

let ffmpegInstance: FFmpeg | null = null;
let isInitializing = false;
let initializationPromise: Promise<FFmpeg> | null = null;

export default async function getEngine(): Promise<FFmpeg> {
    if (ffmpegInstance) {
        return ffmpegInstance;
    }

    if (isInitializing) {
        return initializationPromise!;
    }

    isInitializing = true;

    initializationPromise = (async () => {
        const { coreUrl, wasmUrl } = (window as any).ffmpegAssets || {};
        
        const ffmpeg = new FFmpeg();
        await ffmpeg.load({
            coreURL: coreUrl || `${config.frontendUrl}/ffmpeg-core.js`,
            wasmURL: wasmUrl || `${config.frontendUrl}/ffmpeg-core.wasm`,
        });
        ffmpegInstance = ffmpeg;
        isInitializing = false;
        return ffmpegInstance;
    })();

    return initializationPromise;
}
