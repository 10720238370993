import React from 'react';
import { Outlet } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Navbar from './Navbar';
import AppBar from './AppBar';
import TopBar, { topBarHeight } from './TopBar';

const LayoutWithNavbar: React.FC = () => {
  return (
    <>
      <Box className="appLayout" style={{flexDirection: 'column'}} >
        <Box>
          <Box style={{height: '48px'}}>
          </Box>
          <TopBar />
        </Box>
        <Box className="content" style={{display: 'flex', flexDirection: 'row', flexGrow: 1}}>
          {/*}
          <Box style={{ display:'flex' }}>
            <Navbar />
          </Box>
          */}
          <Box style={{display: 'flex', flexGrow: 1, overflowX: 'hidden'}}>
            <Outlet /> {/* This will render the matched nested route */}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LayoutWithNavbar;

