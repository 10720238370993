import { toBlobURL } from '@ffmpeg/util';
import config from '../constants/constants';

const baseURL = config.frontendUrl;
let preloaded = false;

export async function preloadFFmpegAssets(): Promise<void> {
    if (preloaded) return;
    preloaded = true;

    const coreUrl = await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript');
    const wasmUrl = await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm');

    // Store the URLs in a global object or context
    (window as any).ffmpegAssets = { coreUrl, wasmUrl };
}

preloadFFmpegAssets();
