import React, { useState, useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { useAuth } from './AuthContext';

import mixpanel from 'mixpanel-browser';

import { activateViaToken } from '../api/ServerApi';
import config from '../constants/constants'

type RegistrationViaTokenParams = {
  token: string;
};

const RegistrationViaTokenPage: React.FC = () => {
  const { isAuthenticated, loading, login } = useAuth();

  const navigate = useNavigate();

  const { token } = useParams<RegistrationViaTokenParams>();

  const activateUser = async () => {
    activateViaToken(token as string).then((data) => {
      console.log('Registration successful:', data);

       mixpanel.track('Sign In', {
         'Signin Type': 'Activation'
       })
       mixpanel.identify(data.email)
       mixpanel.people.set({ 
         '$name': data.username,
         '$email': data.email,
         'tenant' : data.tenant
       });

      // Handle login success, e.g., redirect or save the auth token
      login(data.username, data.tenant, data.email, data.fullName, data.isAdmin);
      navigate('/home');
    }).catch ((error) => {
      console.error('Registration error:', error);
      // Handle login error, e.g., show an error message
    });
  };

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }

    if (!token) {
      return;
    }

    activateUser();
  }, [isAuthenticated, navigate]);

  return (
    <div className="loginPage overlay">
      Activating and redirecting...
    </div>
  );
};

export default RegistrationViaTokenPage;

