import React, { useState, useRef, useEffect } from 'react';
import { Box, Card, CircularProgress } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { createVideoFd, uploadVideo } from '../utils/utils';
import { getSignedUploadUrl } from '../api/ServerApi';
import Video from './Video';

import { Frame } from './Frame';
import getEngine from './EffectsEngine';
import { blobUrlToUint8Array } from '../utils/utils';

const Engine = await getEngine();

interface VideoFramesComponentProps {
    video: Video;
    onLoadedVideo: (video: Video) => void;
    numFrames: number;
    leftBackgroundOffset: number;
    rightBackgroundOffset: number;
    size: number;
    hasVideoComponent: boolean;
    hasAudioComponent: boolean;
}

const VideoFramesComponent: React.FC<VideoFramesComponentProps> = ({ video, onLoadedVideo, numFrames, leftBackgroundOffset, rightBackgroundOffset, size, hasVideoComponent, hasAudioComponent }) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [frames, setFrames] = useState<any[]>([]);
    const [audioFrames, setAudioFrames] = useState<any[]>([]);
    const [aspectRatio, setAspectRatio] = useState(1.0);
    const [hasAudio, setHasAudio] = useState(false);
    const [hasVideo, setHasVideo] = useState(false);
    const [uploadProgress, setUploadProgress] = useState<number | null>(null);
    const videoRef = useRef<any>(null);
    const canvasRef = useRef<any>(null);
    const videoFrameHeight = 40;
    const audioFrameHeight = 20;
    const numFrequencyBins = 128;

    const createVideoFdIfNotExists = async (vid: Video) => {
        if (vid.videoFdRef === null) {
            return await createVideoFd(vid);
        }
        return -1;
    }

    const checkVideoState = () => {
      console.log(videoRef.current.readyState);
      setTimeout(checkVideoState, 300);
    }

    useEffect(() => {
      videoRef.current.muted = true;
      videoRef.current.play().then(() => {
        videoRef.current.pause();
        videoRef.current.muted = false;
        if (videoRef.current.readyState >= 3) {
            handleLoadedMetadata();
        } else {
            videoRef.current.addEventListener('canplaythrough', () => {
              handleLoadedMetadata();
            });
        }
      });
    }, [video.url, numFrames, video.duration]);

    useEffect(() => {
      setHasAudio(hasAudioComponent);
      setHasVideo(hasVideoComponent);
    }, [hasVideoComponent, hasAudioComponent]);
    
    const handleUploadProgress = (progress: number) => {
      setUploadProgress(progress);
    }

    const handleLoadedMetadata = () => {
        // get signed url
        getSignedUploadUrl(video.name, video.type, video.sha256).then( async (uploadInformation: { generatedName: string, url: string}) => {
          if (uploadInformation.url) {
            const file = await (await fetch(video.url)).blob();
            // upload the video
            uploadVideo(uploadInformation.url, file, file.type, handleUploadProgress);
          }
        })

        if (video.hasVideo()) {
            setHasVideo(true);
            createVideoFdIfNotExists(video).then((fd: number) => {
                if (fd !== -1) {
                    video.videoFdRef = fd;
                }
                captureBothFrames(video).then(() => {
                    setIsLoading(false);
                    video.videoRef = videoRef.current;
                    onLoadedVideo(video);
                })
            })
        } else {
            setHasVideo(false);
            captureAudioFrames(video).then((res: boolean) => {
                setIsLoading(false);
                video.videoRef = videoRef.current;
                onLoadedVideo(video);
            })
        }
        const curVideoRef = videoRef.current;
        const ratio = curVideoRef.videoHeight / curVideoRef.videoWidth;
        setAspectRatio(ratio);
    };

    const captureVideoFrame = async (video: any, ctx: any, time: any): Promise<string> => {
        return new Promise((resolve) => {
            video.currentTime = time;
            return video.onseeked = async () => {
                const width = canvasRef.current!.width;
                const height = canvasRef.current!.height;

                ctx.clearRect(0, 0, width, height);
                ctx.fillStyle = 'black';
                ctx.fillRect(0, 0, width, height);

                ctx.drawImage(video, 0, 0, canvasRef.current.width, canvasRef.current.height);
                const frame = await canvasRef.current.toDataURL('image/bmp');
                resolve(frame);
            };
        });
    };

    const captureAudioFrame = (data: Float64Array, maxMagnitude: number, ctx: any) => {
        return new Promise((resolve) => {
            const width = canvasRef.current!.width;
            const height = canvasRef.current!.height;

            // Step 1: Set the entire background to black
            ctx.clearRect(0, 0, width, height);
            ctx.fillStyle = 'black';
            ctx.fillRect(0, 0, width, height);

            // Step 2: Define the bar width and spacing
            const spacing = 2; // Space between bars
            //const barWidth = (width / data.length) - spacing;
            const barWidth = 10;

            // Step 3: Create a strong gradient for the bars
            const gradient = ctx.createLinearGradient(0, 0, 0, height);
            gradient.addColorStop(0, '#00FFFF');  // Cyan at the top
            gradient.addColorStop(0.5, '#0000FF'); // Blue in the middle
            gradient.addColorStop(1, '#FF00FF');  // Magenta at the bottom

            // Step 4: Draw the spectrum bars
            for (let i = 0; i < data.length; i++) {
                const magnitude = data[i]; // Assuming magnitude is scaled appropriately
                const x = i * (barWidth + spacing); // Calculate the x position
                const barHeight = (magnitude / maxMagnitude) * height; // Calculate the height of the bar

                // Set fill style to gradient and draw the bar
                ctx.fillStyle = gradient;
                //ctx.fillStyle = '#0000ff';
                ctx.fillRect(x, height - barHeight, barWidth, barHeight);
            }

            // Step 5: Capture the frame as an image
            const frame = canvasRef.current!.toDataURL('image/bmp');
            resolve(frame);
        });
    }

    const captureAudioFrames = async (captureVideo: Video): Promise<boolean> => {
        return blobUrlToUint8Array(captureVideo.url).then((arr: any) => {
            return Engine.writeFile(captureVideo.storageFilePath, arr).then(async (_: any) => {
                // TODO: fix all the casting
                const freqData = (await Engine.get_audio_frequencies([captureVideo.storageFilePath, (numFrequencyBins as unknown) as string, (numFrames as unknown) as string]) as unknown) as Float64Array;
                const maxMagnitude = Math.max(...freqData);
                const freqDataSamples = Array.from({ length: numFrames }, (_, i) => new Float64Array(freqData.buffer, i * numFrequencyBins * Float64Array.BYTES_PER_ELEMENT, numFrequencyBins));

                if (maxMagnitude <= 0) {
                    return false;
                }

                setHasAudio(maxMagnitude > 0);

                const videoElement = videoRef.current;
                const ctx = canvasRef.current.getContext('2d');
                const interval = captureVideo.duration / numFrames;

                let placeholderAudioFrames = [];
                // fill with placeholders only if there are no existing frames showing the video content
                if (frames.length === 0) {
                    for (let i = 0; i < numFrames; i++) {
                        placeholderAudioFrames.push({url: '', index: i, time: 0});
                    }
                    setAudioFrames(placeholderAudioFrames);
                } else {
                    placeholderAudioFrames = [...frames].slice(0, numFrames);
                }

                for (let i = 0; i < numFrames; i++) {
                    const time = i * interval+0.1 + captureVideo.framesOffset;
                    const audioFrame = await captureAudioFrame(freqDataSamples[i], maxMagnitude, ctx);
                    placeholderAudioFrames[i] = {url: audioFrame as string, index: i, time: time};
                    setAudioFrames([...placeholderAudioFrames]);
                }
                setAudioFrames([...placeholderAudioFrames]);
                videoElement.currentTime = captureVideo.framesOffset;
                videoElement.onseeked = () => {
                }
                return true;
            })
        })
    }

    const captureBothFrames = async (captureVideo: Video) => {
        await blobUrlToUint8Array(captureVideo.url).then((arr: any) => {
            return Engine.writeFile(captureVideo.storageFilePath, arr).then(async (_: any) => {
                // TODO: fix all the casting
                const freqData = (await Engine.get_audio_frequencies([captureVideo.storageFilePath, (numFrequencyBins as unknown) as string, (numFrames as unknown) as string]) as unknown) as Float64Array;
                const maxMagnitude = Math.max(...freqData);
                const freqDataSamples = Array.from({ length: numFrames }, (_, i) => new Float64Array(freqData.buffer, i * numFrequencyBins * Float64Array.BYTES_PER_ELEMENT, numFrequencyBins));

                const videoHasAudio = maxMagnitude > 0 && hasAudioComponent;
                setHasAudio(videoHasAudio);

                const videoElement = videoRef.current;
                const ctx = canvasRef.current.getContext('2d');
                const interval = captureVideo.duration / numFrames;

                let placeholderFrames = [];
                let placeholderAudioFrames = [];
                // fill with placeholders only if there are no existing frames showing the video content
                if (frames.length === 0) {
                    for (let i = 0; i < numFrames; i++) {
                        placeholderFrames.push({url: '', index: i, time: 0});
                        placeholderAudioFrames.push({url: '', index: i, time: 0});
                    }
                    setFrames(placeholderFrames);
                    setAudioFrames(placeholderAudioFrames);
                } else {
                    placeholderFrames = [...frames].slice(0, numFrames);
                    placeholderAudioFrames = [...frames].slice(0, numFrames);
                }

                for (let i = 0; i < numFrames; i++) {
                    const time = i * interval+0.1 + captureVideo.framesOffset;
                    const audioFrame = await captureAudioFrame(freqDataSamples[i], maxMagnitude, ctx);
                    const videoFrame = await captureVideoFrame(videoElement, ctx, time);
                    placeholderFrames[i] = {url: videoFrame as string, index: i, time: time};
                    placeholderAudioFrames[i] = {url: audioFrame as string, index: i, time: time};
                    setFrames([...placeholderFrames]);
                    setAudioFrames([...placeholderAudioFrames]);
                }
                setAudioFrames([...placeholderAudioFrames]);
                setFrames([...placeholderFrames]);
                videoElement.currentTime = captureVideo.framesOffset;
                videoElement.onseeked = () => {
                }
            })
        })
    };

    return (
        <Box sx={{ pointerEvents: 'none', width: '100%', display: 'flex', height: videoFrameHeight + audioFrameHeight }}>
          {(isLoading || (uploadProgress !== null && uploadProgress !== 100)) && (
          <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '60px', // Ensure height matches your requirement
              backgroundColor: 'rgba(0, 0, 0, 0.4)', // Darken background slightly for visibility
              zIndex: 1,
              color: '#fff',
            }}
          >
            {/* Circular progress compact */}
            <CircularProgress size={24} sx={{ color: '#00ADEF', mr: 1 }} />

            {uploadProgress !== null && (
            <Box>
              {/* Cloud upload icon with smaller size */}
              <CloudUploadIcon sx={{ fontSize: 24, color: '#fff', mr: 1 }} />

              {/* Upload percentage, sized to fit the space */}
              <Box
                sx={{
                  fontWeight: 'bold',
                  fontSize: '0.9rem',
                }}
              >
                {`${uploadProgress}%`}
              </Box>

              {/* Gradient progress bar at the bottom of the 60px height box */}
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  height: '4px', // Make the progress bar more compact
                  background: 'linear-gradient(90deg, #00ADEF 0%, #00EFA1 100%)',
                  width: `${uploadProgress}%`,
                  borderRadius: '2px',
                }}
              />
            </Box>
            )}
          </Box>
          )}
        <Card
          sx={{
            overflow: 'hidden',
            flexGrow: 1,
            display: 'flex',
            opacity: 0.0,
          }}
        />
        <video ref={videoRef} src={video.url} preload="auto" style={{ display: 'none' }} playsInline/>
        <canvas ref={canvasRef} style={{ display: 'none' }} />
        <Box
          sx={{
            display: 'flex',
            position: 'absolute',
            top: 0,
            left: `${leftBackgroundOffset}px`,
            width: `${-leftBackgroundOffset}px`,
            height: '100%',
            backgroundColor: 'black',
            zIndex: 0,
            opacity: 0.5,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: 0,
            left: `${leftBackgroundOffset}px`,
            width: `${size}px`,
            height: '100%',
            zIndex: -1,
          }}
        >
          {hasVideo && (
            <Box
              sx={{
                display: 'flex',
                flexGrow: 2, // Make the video frame take 2/3 of the height
                maxHeight: hasAudio ? videoFrameHeight : '100%', // TODO: why doesnt it work without this
                width: '100%',
              }}
            >
              {frames.map((frame, index) => (
                <Box
                  key={index}
                  sx={{
                    flexGrow: 1,
                    width: videoFrameHeight / aspectRatio,
                    borderRight: '1px solid white',
                  }}
                >
                  <Frame imageUrl={frame.url} index={frame.index} displayLoadingImage={false} />
                </Box>
              ))}
            </Box>
          )}
          {hasAudio && (
            <Box
              sx={{
                flexGrow: 1, // Make the audio frame take 1/3 of the height
                minHeight: audioFrameHeight, // TODO: why doesnt it work without this
                width: '100%',
                display: 'flex',
                overflow: 'hidden',
              }}
            >
              {audioFrames.map((frame, index) => (
                <Box
                  key={index}
                  sx={{
                    flexGrow: 1,
                    width: audioFrameHeight / aspectRatio,
                    borderRight: '1px solid white',
                  }}
                >
                  <Frame imageUrl={frame.url} index={frame.index} displayLoadingImage={false} />
                </Box>
              ))}
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            position: 'absolute',
            top: 0,
            left: `${size + leftBackgroundOffset - rightBackgroundOffset}px`,
            width: `${rightBackgroundOffset}px`,
            height: '100%',
            backgroundColor: 'black',
            zIndex: 0,
            opacity: 0.5,
          }}
        />
      </Box>
    );
};

export { VideoFramesComponent };
