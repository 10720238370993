import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';

import { PhotoCamera } from '@mui/icons-material';

interface FrameProps {
  index: number;
  imageUrl: string;
  displayLoadingImage?: boolean;
}

const Frame: React.FC<FrameProps> = ({ imageUrl, index, displayLoadingImage = true }) => {
  const [currentFrame, setCurrentFrame] = useState<string>(imageUrl);

  useEffect(() => {
    setCurrentFrame(imageUrl);
  }, [imageUrl])

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      {!currentFrame && (displayLoadingImage ? 
          <PhotoCamera
            sx={{ height: '100%', fontSize: 80, color: '#bbb' }} // Adjust size and color as needed
          />
        :
          <Box
            sx={{ height: '100%', fontSize: 80, color: '#bbb' }} // Adjust size and color as needed
          />
      )
      }
      {currentFrame && (
        <img src={currentFrame} alt={`Frame ${index}`} height={'100%'} width={'100%'} />
      )}
    </Box>
  );
};

export { Frame };
