import React, { useState, FormEvent } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { useAuth } from './AuthContext';

import styles from './LoginPage.module.css'

import { resetPasswordViaToken } from '../api/ServerApi';
import config from '../constants/constants'

type RegistrationViaTokenParams = {
  token: string;
};

const ResetPasswordViaTokenPage: React.FC = () => {
  const { isAuthenticated, loading, login } = useAuth();
  const [password, setPassword] = useState('');

  const navigate = useNavigate();

  const { token } = useParams<RegistrationViaTokenParams>();

  const handleSubmit = async (event: FormEvent) => {
    console.log(token);
    event.preventDefault();
    if (!token) {
      return;
    }

    resetPasswordViaToken(token, password).then((data) => {
      console.log('Reset successful:', data);

      // Handle login success, e.g., redirect or save the auth token
      login(data.username, data.tenant, data.email, data.fullName, data.isAdmin);
      navigate('/home');
    }).catch ((error) => {
      console.error('Reset error:', error);
      // Handle login error, e.g., show an error message
    });
  };

  if (isAuthenticated) {
    return <Navigate to="/home" replace />;
  }

  return (
    <div className="loginPage overlay">
      <form className={styles.form} onSubmit={handleSubmit}>
        <div>
          <label className={styles.label} htmlFor="password">Password:</label>
          <input
            className={styles.input}
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button className={styles.button} type="submit">Reset Password</button>
      </form>
    </div>
  );
};

export default ResetPasswordViaTokenPage;

