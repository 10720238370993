import blurImage from '../assets/filter_blur.webp';
import blackWhiteImage from '../assets/filter_black_and_white.webp';
import sepiaImage from '../assets/filter_sepia.webp';
import vignetteImage from '../assets/filter_vignette.webp';
import sharpenImage from '../assets/filter_sharpen.webp';
import invertImage from '../assets/filter_invert.webp';
import pixelateImage from '../assets/filter_pixelate.webp';
import sketchImage from '../assets/filter_sketch.webp';
import filmGrainImage from '../assets/filter_film_grain.webp';
import grayscaleImage from '../assets/filter_grayscale.webp';
import contrastBoostImage from '../assets/filter_contrast_boost.webp';
import brightnessBoostImage from '../assets/filter_brightness_boost.webp';
import hueShiftImage from '../assets/filter_hue_shift.webp';
import oldFilmImage from '../assets/filter_old_film.webp';
import tiltShiftImage from '../assets/filter_tilt_shift.webp';
import edgeDetectImage from '../assets/filter_edge_detect.webp';
import posterizeImage from '../assets/filter_posterize.webp';
import glowImage from '../assets/filter_glow.webp';
import distortionImage from '../assets/filter_distortion.webp';
import embossImage from '../assets/filter_emboss.webp';

type Effect = {
    name: string;
    image: string;
    description: string;
    effectString: string;
};

const effectsData: Effect[] = [
    {
        name: 'Blur',
        image: blurImage,
        description: 'Add a blur to your video.',
        effectString: 'gblur=sigma=40',
    },
    {
        name: 'Grayscale',
        image: grayscaleImage,
        description: 'Convert your video to grayscale.',
        effectString: 'format=gray',
    },
    //{
    //    name: 'Black & White',
    //    image: blackWhiteImage,
    //    description: 'Convert your video to black and white.',
    //    effectString: 'hue=s=0',
    //},
    {
        name: 'Sepia',
        image: sepiaImage,
        description: 'Give your video a warm, antique look.',
        effectString: 'colorchannelmixer=.393:.769:.189:0:.349:.686:.168:0:.272:.534:.131',
    },
    {
        name: 'Vignette',
        image: vignetteImage,
        description: 'Add a vignette effect around the edges of the video.',
        effectString: 'vignette',
    },
    {
        name: 'Sharpen',
        image: sharpenImage,
        description: 'Sharpen the video details.',
        effectString: 'unsharp=5:5:1.0:5:5:0.0',
    },
    //{
    //    name: 'Invert',
    //    image: invertImage,
    //    description: 'Invert the colors of the video.',
    //    effectString: 'negate',
    //},
    {
        name: 'Pixelate',
        image: pixelateImage,
        description: 'Pixelate the video for a retro look.',
        effectString: 'scale=iw/10:ih/10,scale=iw*10:ih*10',
    },
    //{
    //    name: 'Sketch',
    //    image: sketchImage,
    //    description: 'Simulate a hand-drawn sketch.',
    //    effectString: 'edgedetect=low=0.1:high=0.3,negate',
    //},
    {
        name: 'Film Grain',
        image: filmGrainImage,
        description: 'Add simulated film grain to give the video an old, cinematic look.',
        effectString: 'noise=alls=80:allf=t+u',
    },
    {
        name: 'Contrast Boost',
        image: contrastBoostImage,
        description: 'Increase the contrast of the video.',
        effectString: 'eq=contrast=1.5',
    },
    {
        name: 'Brightness Boost',
        image: brightnessBoostImage,
        description: 'Increase the brightness of the video.',
        effectString: 'eq=brightness=0.1',
    },
    {
        name: 'Hue Shift',
        image: hueShiftImage,
        description: 'Shift the hue of the video.',
        effectString: 'hue=h=60',
    },
    {
        name: 'Old Film',
        image: oldFilmImage,
        description: 'Apply an old film effect to the video.',
        effectString: 'curves=vintage',
    },
    {
        name: 'Tilt Shift',
        image: tiltShiftImage,
        description: 'Create a tilt-shift effect to simulate miniature scenes.',
        effectString: 'lenscorrection=k1=0.5:k2=0.5',
    },
    {
        name: 'Edge Detect',
        image: edgeDetectImage,
        description: 'Highlight edges in the video.',
        effectString: 'edgedetect=mode=colormix',
    },
    {
        name: 'Posterize',
        image: posterizeImage,
        description: 'Reduce the color palette of the video.',
        effectString: 'lutrgb=r=if(gte(val\\,128)\\,255\\,0):g=if(gte(val\\,128)\\,255\\,0):b=if(gte(val\\,128)\\,255\\,0)',
    },
    {
        name: 'Glow',
        image: glowImage,
        description: 'Add a glow effect to the video.',
        effectString: 'gblur=sigma=20,eq=contrast=1.5:brightness=0.2',
    },
    {
        name: 'Distortion',
        image: distortionImage,
        description: 'Distort the video for a funhouse effect.',
        effectString: 'lenscorrection=k1=-0.5:k2=-0.5',
    },
    {
        name: 'Emboss',
        image: embossImage,
        description: 'Add an emboss effect to the video.',
        effectString: 'convolution=\'-2 -1 0 -1 1 1 0 1 2\'',
    },
];

// Map for quick lookup by name
const effectNameToStringMap: { [key: string]: string } = {};

effectsData.forEach((effect) => {
    effectNameToStringMap[effect.name] = effect.effectString;
});

export {
  type Effect,
  effectsData,
  effectNameToStringMap
};
